import React, { useEffect, useCallback,memo, useState, useRef, Profiler } from 'react';
//import { withRouter } from 'react-router';
import { Messages, ChatInput, Status, Sidebar } from 'chatSource/containers';
//import { connect } from 'react-redux';
//import store from 'chat/redux/store';
import {useMediaQuery  } from 'react-responsive'
//import find from 'lodash/find';
//import { Route, Redirect, Switch,useParams  } from "react-router-dom";
import mainRoute from 'chatSource/routes'; 

import './Home.scss';
import 'chatSource/styles/layouts/chat.scss';

import { dialogsActions, messagesActions } from 'chatSource/redux/actions';

import { useAppState } from "chatSource/redux/reducers/dialogs";
import { useAppMessagesState } from "chatSource/redux/reducers/messages";

import BlinkMP3 from "chatSource/assets/mp/bb2.mp3";


import {useSocket} from 'chatSource/context/socket'; //, socket as sockett   socket,SocketContext,


//import { useLocalStorage } from "chatSource/useLocalStorage";

import { useHistory } from "react-router-dom";

// вот эта конструкция странно работает. то да, то нет
/*const useRefEventNewMessage = (fn) => {
  const fnRef = useRef(fn);
  fnRef.current = fn;
  return fnRef;
};
const useRefEventReconnect = (fn) => {
  const fnRef = useRef(fn);
  fnRef.current = fn;
  return fnRef;
};
const useRefEventConnect = (fn) => {
  const fnRef = useRef(fn);
  fnRef.current = fn;
  return fnRef;
};

const useRefEventDialogReaded = (fn) => {
  const fnRef = useRef(fn);
  fnRef.current = fn;
  return fnRef;
};
*/




//<RightDesktop/>	
const CompositionMobile = props => {  //, isCreating
	//console.log(store.getState());
//вроде ок.
//надо обдработать назад
//и как то выровнять чуток сообзения. наверно какой то паддинг
	alert('CompositionMobile дотестиь');
    var currentDid = props.params.id;
	let view;
	if (currentDid === undefined) {
		view = <Sidebar sidebarVisible={props.sidebarVisible} setsidebarVisible={props.setsidebarVisible}  />
	} else {
		view = <RightDesktop user={props.user} currentDialogId={currentDid} currentDialog={props.currentDialog} 
		mainDispatcher={props.mainDispatcher}
		/>	;
	}
	return (
	<>
	{view}
  	 
	</> 
    )


	
}

// тоже показывает, что монтируется
// тут она тк если внутри, то она часто пересоздается и зацикливается
//а пересоздается она только изза контекста. и понеслось дальне ниже
const CompositionDesktop = ({user,isDesktopOrLaptop1,sidebarVisible,setsidebarVisible,currentChatType,currentChatDoc,currentDialog
,mainDispatcher
}) => {
        if (user && isDesktopOrLaptop1) {
			return (
				<CompositionDesktopPure isDesktopOrLaptop={isDesktopOrLaptop1} sidebarVisible={sidebarVisible} setsidebarVisible={setsidebarVisible} 
				user={user}
				currentChatType={currentChatType}
				currentChatDoc={currentChatDoc}
				currentDialog={currentDialog}
				mainDispatcher={mainDispatcher}
				/>		 

			)
		} else {
			return null
		}
}
CompositionDesktop.displayName='CompositionDesktop';


// ТОЖЕ ПЕРЕСТАЛ РЕРЕНДЕРИТЬСЯ !!!!!
const CompositionDesktopPure = memo(
 ({isDesktopOrLaptop,sidebarVisible,setsidebarVisible,user,currentChatType,currentChatDoc,currentDialog,mainDispatcher}) => {
			return (
				<Profiler id="sidebarInCompositionDesktop2" onRender={onRenderCallback}>		 
				<Sidebar sidebarVisible={sidebarVisible} setsidebarVisible={setsidebarVisible} />  
					 <RightDesktop isDesktopOrLaptop={isDesktopOrLaptop} user={user} currentChatType={currentChatType} currentChatDoc ={currentChatDoc}
					currentDialog={currentDialog}
					mainDispatcher={mainDispatcher}
					 />	
				</Profiler>					 

			)
}
)

CompositionDesktopPure.displayName='CompositionDesktopPure';

CompositionDesktop.whyDidYouRender = true
CompositionDesktopPure.whyDidYouRender = true

// рендерится если ДЕСКТОП
	const  RightDesktop = ({isDesktopOrLaptop,sidebarVisible,user,currentChatType,currentChatDoc,currentDialog,mainDispatcher}) => {
		let chatVisibleStyle;
			const [state, dispatch] = useAppState();
			
			const [scrollPos, setScrollPos] = useState(999999999); 
		
		  if (isDesktopOrLaptop) {
//				 chatVisibleStyle = { width: '420px', display: sidebarVisible ? null : 'none'};
		  } else 
		  {
				 //chatVisibleStyle = { flex: 1, display: sidebarVisible ? null : 'none'};	  
				 chatVisibleStyle = { width: '100%', display: sidebarVisible ? null : 'none'};	  
		  }
		  
			var partner = undefined;
			var partnerId = undefined;
			if (currentDialog) {
				//partner= user !== currentDialog.PARTNER.ID ? currentDialog.STARTER : currentDialog.PARTNER;
				partnerId= user !== currentDialog.PARTNER ? currentDialog.PARTNER :  currentDialog.STARTER
				
				partner = state.participants.filter(item => item.ID == partnerId)[0];
			}
		return ( 
          <div className="mychat__dialog">		  
            <Status /> 
            <Profiler id="message event" onRender={onRenderCallback}>		 			
            <Messages isDesktopOrLaptop={isDesktopOrLaptop} uId = {user} currentChatType={currentChatType} currentChatDoc ={currentChatDoc}
			mainDispatcher={mainDispatcher}
  partner={partner}
  scrollPos={scrollPos}
  setScrollPos={setScrollPos}
			/> 
			</Profiler>
            <div className="mychat__dialog-input">
              <ChatInput setScrollPos={setScrollPos} /> 
            </div>
			
          </div>			
		)
		

		
	}
	CompositionDesktopPure.whyDidYouRender = true
	RightDesktop.whyDidYouRender = true


	function onRenderCallback(
  id, // проп "id" из дерева компонента Profiler, для которого было зафиксировано изменение
  phase, // либо "mount" (если дерево было смонтировано), либо "update" (если дерево было повторно отрендерено)
  actualDuration, // время, затраченное на рендер зафиксированного обновления
  baseDuration, // предполагаемое время рендера всего поддерева без кеширования
  startTime, // когда React начал рендерить это обновление
  commitTime, // когда React зафиксировал это обновление
  interactions // Множество «взаимодействий» для данного обновления 
) {
  // Обработка или логирование результатов...
 // alert(phase + ' '+id);
 console.log(phase + ' '+id);
}

const HomeContainer = (props) => {
	console.log('home!!!!!!!!!!!!!!'); //user, 
 // const { dialogId } = props; // первое берется из connect dialogsActions  
//  var dialogId;  
	//alert(props.history);
	const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })

  
  
  const [sidebarVisible, setsidebarVisible] = useState(true); // 
  
	let history = useHistory();
  
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })  

   const [state, dispatch] = useAppState();
  const [messagesState, messagesDispatch] = useAppMessagesState();


   
   const user = state.data.id;
  //const chatconnected = socket.connected;
  //const chatsocket = socket;
    const [chatsocket,chatconnected] =  useSocket(user,3);   

  
   //alert(JSON.stringify(state));
   //alert(state.data.id);
   console.log(JSON.stringify(state));
  
   //console.log('????????????????????');
   //console.log(state.data);

   //тут надо взять ИД диалога, если он есть в стейте. если нет - в пропсах
   // и положим в стейт
//   alert(JSON.stringify(props));
   var currentChatDoc = state.currentChatDoc ? state.currentChatDoc : props.params.id;// props.currentChatDoc;

   //console.log('props');
   //console.log(props);
   if ((!state.currentChatDoc) && (props.params.id)) {
	   //dialogsActions.setCurrentDialogId(currentDialogId,dispatch); 
	   dialogsActions.setCurrentDialogParams(props.params.type,props.params.id,dispatch); 
   }
   
	
   var currentDialog = undefined;
   var z = state.messages.filter(item => item.CHAT_DOC_ID == state.currentChatDoc && item.CHAT_TYPE == state.currentChatType);
   if (z.length !=0) {
	   currentDialog = z[0];//.CHAT_ID
   }
	

var isDesktopOrLaptop1 =  isDesktopOrLaptop;
var isCreating = false;
var creatingType = 0;
var creatingDocId = 0;
//alert(JSON.stringify(state));
	if ((props.path === mainRoute+"/create/:docid/:type") && (!state.isCreating)) {
		// если у нас создание
		//alert('creating');
		isCreating = true;
		creatingType = props.params.type;
		creatingDocId = props.params.docid;
		dispatch(dialogsActions.startCreatingDialog(creatingType,creatingDocId));
		//alert('creatings '+state.isCreating );
	} 
	// суперриво. но работает
	//http://localhost:3004/chatt/create/98170/133   
	if ((props.path === mainRoute+"/dialog/:type/:id") && (state.isCreating)) {
	//	alert(state.isCreating);
	  if (state.isCreating)
	  {
		//  alert('clearing1');
	  dispatch(dialogsActions.clearCreatingDialog());
	//  alert('clearing2');
	  }
		
		
	}

const processOnlines = useCallback (

(data) => {
	// вроде как удачно меняет стейт в хранилище
	//alert('processonlines');
	///alert(JSON.stringify(data));
	dispatch(dialogsActions.setUserOnlineState(data.userId,data.onlineState));	
	
}

);

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const processDialogReaded =  useCallback (

(data) => {
	// пришло, что такой то диалог прочитан полностью
	//надо 
	//в стейте найти диалог и у всех сообщений проставить highlite 0
	
	messagesDispatch(messagesActions.messagesClearHighLight(data));
	
	// тут надо еще у диалога конкретного UNREADED_OTHER поставить НОЛЬ. чтоб нен подсвечивался
	dispatch(dialogsActions.clearDialogUnreadedOther(data));	
	
	//тоже самое в localStorage
	//2022
	//export const useLocalStorage = (key, keyValue, rootKey) => {
		

	var msgs = localStorage.getItem('msgs'+'_'+state.data.id);
	if (msgs) {
		msgs = JSON.parse(msgs);
		if (msgs[data]) {
			for (var i=0; i< msgs[data].MESSAGES.length; i++) {
				msgs[data].MESSAGES[i].HIGHLIGHT=0;
				
			}
			msgs = JSON.stringify(msgs);
			localStorage.setItem('msgs'+'_'+state.data.id,msgs);
			
		}
		
	}
	//
	//
	//
	//
	//
	//
	
}, [messagesDispatch]

);

const reconnect = (data) => { 
	//alert('reconnect');
	//TODO тут еще делать блокировку ввода чата
};

// вот это работает. срабатывает и на смену диалога и на соединение. но сомнительно
// те при смене диалога
const connect = useCallback(
(data) => { 
	// надо кинуть опять онлайн. иначе не будет подписки и не будут приходить сообщения 
	// вроде нормально отрабабатывает при переконнекте при потере соединения
		//chatsocket.emit('USER:ONLINE', user)
		
	//	alert('effect in home0');
 //     if (chatconnected) { // chatsocket.connected
// 		chatsocket.emit('USER:ONLINE', state.data.id);
//alert('effect in home');

//	  }		  
		
	
},[chatconnected,state.currentChatDoc]
)
;
///////////////////////////////////////////////////////////////////////////////////////////////////////////////
// https://stackoverflow.com/questions/55265255/react-usestate-hook-event-handler-using-initial-state
// вот такая вот конструкция чтоб иметь доступ к state

// тут стейт новый не видело, тк ниже при добавлении eventhandler ставилась только первая версия функции
const newMessage1 =  (data) => {
	alert('data');
	alert('0 - currentDialog = '+state.currentChatDoc+' '+data.message.DIALOG_ID);
}

const newMessage = useCallback (
(data) => { 

		console.log('asdasdasd');
		console.log(state);
		//alert(JSON.stringify(data));
		
		//alert('new msg');
		
 //надо добавить сообщение в хранилище. Но это хранилизе текущего диалога. если это не текущий - не добавится. и не должно
     //dispatch(dialogsActions.addMessage(data));
	 //alert('1');
	 console.log('useCallback 1');
	 var isCreating = false;
	 //alert('2');
	 console.log('useCallback 2');
	 console.log('new_message');
	 console.log(data);
	 if (data.isCreating) {
		 // это сообщение в только что созданном диалоге
		// alert('3');
		 console.log('useCallback 3');
		 delete data.isCreating;
		 isCreating = true;
		 // так как тут у нас новый диалог, которого нет в уже загруженных, его описание пришло с сообщением:
		 // data.dialogInfo
		 // !!!!!!!!!!вот тут вот срабатывает первый раз загрузка сообщений с сервера
		 // так как в контейнере сообьщений залочен эффект на список диалогов и текущий документ. ТАК НАДО
		 // диалоги изменяются, срабатывает эффект и загружает сообщения. хотя неправильно. тк эффект будет срабатывает даже на сервисных изменениях данных диалогов
		 dispatch(dialogsActions.addNewDialog(data.dialogInfo)); 



	 }
	 //alert('4');
	 console.log('useCallback 4');
	 if (data.message.USER.ID == state.data.id) {
		// alert('5');
		 console.log('useCallback 5');
	   data.message.HIGHLIGHT=1;// хайлалйтим только у себя. позже она снимется когда прочитает оппонент
	  }
	  //alert('6');
	  console.log('useCallback 6');
	  
	  messagesActions.addMessage(data.message,messagesDispatch,state);
	
		 
	 //return
//	alert('Nee'); 
 //и увеличить колво непрочитанных соответствущего диалога
 if (state.currentChatDoc == data.message.DIALOG_DOC_ID && state.currentChatType == data.message.DIALOG_TYPE) {

	   // иначем ставим НОЛЬ непрочитанных для диалога. если он ТЕКУЩИЙ
	//   dialogsActions.updateReadedStatusLocal(data.DIALOG_ID,dispatch);
	 
	 
    } else {
	 
 // увеличиваем количество непрочитанных сообщений в нужном диалоге. только если он не текущий
	// но только если это сообщение не при создании диалога
     if (!isCreating) 
	 {
		 //alert('inc');
		// alert('2 - inc '+data.message.DIALOG_ID);
		console.log('incrementing '+data.message.DIALOG_ID+ ' '+typeof data.message.DIALOG_ID);
         dispatch(dialogsActions.incrementReaderMessagesCounter(data.message.DIALOG_ID));
	 }
    }
 //
	   // и теперь ставим в списке сообщений конкретного диалога последнее сообщение

	  // dispatch(dialogsActions.addLastMessageToDialogsList(data.DIALOG_ID,data));
	  // сборно меняет. сообщение ставить должно безусловно. сбрасывать флаг чтения только если диалог текущий
	 // alert('3 - updateReadedStatusAndSetLastMsgInfo');
	 //!!!!!!!! вот тут вот второй раз срабаывает загрузка сообщений с сервера
	  dispatch(dialogsActions.updateReadedStatusAndSetLastMsgInfo(data.message.DIALOG_ID,data.message,((state.currentChatDoc == data.message.DIALOG_DOC_ID && state.currentChatType == data.message.DIALOG_TYPE))));

  //играем только НЕ У СЕБЯ
        if (state.data.id == data.message.USER.ID) {
			
			
		} else 
		{
			var ChirpChirp = new Audio (BlinkMP3);
			ChirpChirp.play();
			
		}
	  console.log('asdasdasdasd1');console.log(data.message);
		
		console.log('zuseCallback '+isCreating);
   if (isCreating) { // только если сообщение не мое
       console.log('zuseCallback1 '+isCreating);
	   console.log('creating '+JSON.stringify(data.message));
	   if (data.message.USER.ID == state.data.id) {
		   console.log('zuseCallback2 '+isCreating);
	   //перейти по роуту диалога
	   // если это создание, то перейти по роуту диалога
	   //history.push('/chatt/dialog/'+data.message.DIALOG_ID);
	   // да. тут надо сменить ид диалога. тк роут уже отрисован и ничего не выполнится
	   // TODO сделать наверно чтоб ИД брал из роута
	   }
   }	   

}
,[state.currentChatDoc]
);
//console.log('const handleNewMessageRef = useRefEventNewMessage(newMessage);');



//const handleNewMessageRef = useRefEventNewMessage(newMessage);
//const handleReconnectRef = useRefEventReconnect(reconnect);
//const handleConnectRef = useRefEventConnect(connect);
//const handleDialogReadedRef = useRefEventDialogReaded(processDialogReaded);

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
	//  alert('z'+chatconnected);
	  
	return function cleanup() {
        //console.log('learnup socket1');
	//	alert('cleanup 0'); //handleNewMessageRef.current

    }	
	  
  }, [chatconnected]);

  useEffect(() => {
		// вроде как один раз срабатывает, но может быть косяк, когда сокет будет в состоянии неподключенный TODO
//			  if (state.data) {
//				var uId = state.data.id;
//				chatsocket.emit('USER:ONLINE', uId);
				//alert('setting online');
//			  }
	  
  
//, connected
  }, [chatsocket,state.data]);


  useEffect(() => {
	  // вроде как удачно ставит ивент и потом подчищает
	 // alert('sett on connect ones');
//			chatsocket.on('connect', 
			// только так виден нужный стейт в функции что по ссылке
			//e => 
			//handleConnectRef.current(e)
			//handleNewMessageRef.current
//			connect
			//); 
	return function cleanup() {
        //alert('learnup socket1');
//		chatsocket.off('connect',connect); //handleNewMessageRef.current

    }	
	  
  },[chatsocket,state.currentChatDoc,chatconnected]);

  useEffect(() => {  // все равно срабатывает при соедиеннии два раза. тк используется два хука в разных местах
	  //alert('h'+chatconnected+' '+uId);
	  if (chatconnected) {
			  if (state.data) {
				var uId = state.data.id;
				chatsocket.emit('USER:ONLINE', uId);
				//alert('setting online');
			  }

	  }		  
  }, [chatconnected]); //state


  useEffect(() => {

//		alert('setter '+chatconnected);
	//	alert('z '+chatconnected);

	  if (chatconnected)  // вроде как срабатывает один раз
	  {
		  //alert('setting');
			chatsocket.on('SERVER:NEW_MESSAGE', 
			// только так виден нужный стейт в функции что по ссылке
			//e => 
			//handleNewMessageRef.current(e)
			//handleNewMessageRef.current
			newMessage
			); 
//			chatsocket.on('SERVER:NEW_MESSAGE', 
			// только так виден нужный стейт в функции что по ссылке
			//e => 
			//handleNewMessageRef.current(e)
			//handleNewMessageRef.current
//			newMessage1
//			); 

//			chatsocket.on('disconnect', 
			// только так виден нужный стейт в функции что по ссылке
//			e => 
//			handleReconnectRef.current(e)
			//handleNewMessageRef.current
			//newMessage
//			); 

			 
		chatsocket.on('SERVER:DIALOG_READED', 
		//e => 
		//handleDialogReadedRef.current(e)
		processDialogReaded
			//e => 
			//handleDialogReadedRef.current(e)
			); 
		chatsocket.on('SERVER:ONLINERS', 
		//e => 
		//handleDialogReadedRef.current(e)
		processOnlines
			//e => 
			//handleDialogReadedRef.current(e)
			); 


	  }
	  //вот это вот постоянно выполняется. почему хз
	  //вот тут вот не очищается
	return function cleanup() {
        //console.log('learnup socket1');
		chatsocket.off('SERVER:NEW_MESSAGE',newMessage); //handleNewMessageRef.current
		chatsocket.off('SERVER:DIALOG_READED',processDialogReaded); //handleDialogReadedRef.current
		chatsocket.off('SERVER:ONLINERS',processOnlines); //handleDialogReadedRef.current
		//chatsocket.off('disconnect',handleReconnectRef.current); //handleNewMessageRef.current
		//chatsocket.off('connect',connect); //handleNewMessageRef.current

    }	
	  
//, connected
  },[chatconnected,state.currentChatDoc]); //тут именно такая зависимость, тк иначе на event висит старая версия newMessage





//  useEffect(() => {
    

//  },[]);//, [props.location.pathname]); // привязано к адресу


	//console.log(store.getState());
	

    
	return <Home isDesktopOrLaptop={isDesktopOrLaptop1} user={user} 
    currentChatType={props.currentChatType}
	currentChatDoc={props.currentChatDoc}
	sidebarVisible={sidebarVisible} setsidebarVisible ={setsidebarVisible} 
	currentDialog={currentDialog}
	mainDispatcher={dispatch}
	/> 
	
	
}
// удачно перестал работать лишний rerender
const Home = memo(
({isDesktopOrLaptop,user,currentChatType,currentChatDoc,sidebarVisible,setsidebarVisible,currentDialog,mainDispatcher }) => {

	

  return (
	<Profiler id="HomeRoot" onRender={onRenderCallback}>

    <section className="home">
      <div className="mychat">
	<Profiler id="sidebarInCompositionDesktop1" onRender={onRenderCallback}>
		<CompositionDesktop isDesktopOrLaptop1={isDesktopOrLaptop} user = {user}  sidebarVisible={sidebarVisible} setsidebarVisible ={setsidebarVisible} 
		currentChatType = {currentChatType}
		currentChatDoc = {currentChatDoc}
		currentDialog={currentDialog}
		mainDispatcher={mainDispatcher}
		/>	

</Profiler>			

        {user && !isDesktopOrLaptop && (
		<CompositionMobile 
		
		currentChatType = {currentChatType}
		currentChatDoc = {currentChatDoc}
		
		sidebarVisible={sidebarVisible} setsidebarVisible={setsidebarVisible} 
		user={user}
		currentDialog={currentDialog}
		mainDispatcher={mainDispatcher}
		/>	
        )}

      </div>
    </section>
</Profiler>	
  );
}
);

Home.whyDidYouRender = true
Home.displayName = 'Home';
export default  HomeContainer;

