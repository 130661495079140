import React, { Fragment,memo } from "react";
import PropTypes from "prop-types";
import { Button, Input } from "antd-v3"; //, Upload
//import { UploadField } from "@navjobs/upload";
import { Picker } from "emoji-mart"; //,Emoji
//import reactStringReplace from 'react-string-replace';

import { UploadFiles } from "chatSource/components";

import "./ChatInput.scss";

const { TextArea } = Input;

const ChatInput = memo(
(props) => {
  const {
    emojiPickerVisible,
    value, // сам текст сообщения
    addEmoji,
	clickEmo,
    setValue,
    handleSendMessage,
    sendMessage,
    toggleEmojiPicker,
    attachments,
    onSelectFiles,
    isRecording,
    onRecord,
    onHideRecording,
    removeAttachment,
    isLoading,
	dispatch,
	messagesDispatch,
	
	disabledTextInput,
	nulledCurrentDialogId
  } = props;

  return (
    <Fragment>
      <div className="chat-input">
        <div>
          <div className="chat-input__smile-btn">
            <div className="chat-input__emoji-picker">
              {emojiPickerVisible && (
			  /** тут интересно emojiTag это из библиотеки передается объект, а в  addEmoji параметры должны совпадать с именем свойств объекта  */
                <Picker onSelect={emojiTag => addEmoji(emojiTag)} onClick={(emoji, event) => clickEmo(emoji,event)} set="apple" />
              )}
            </div>
			{/**            <Button   кнопка эмоций убрана
              onClick={toggleEmojiPicker}
              type="link"
              shape="circle"
              icon="smile"
            />*/}
          </div>
          {isRecording ? (
            <div className="chat-input__record-status">
              <i className="chat-input__record-status-bubble"></i>
              Recording...
              <Button
                onClick={onHideRecording}
                type="link"
                shape="circle"
                icon="close"
                className="stop-recording"
              />
            </div>
          ) : (
            <TextArea
              onChange={setValue}
              onKeyUp={handleSendMessage}
              size="large"
              placeholder= { nulledCurrentDialogId}
              value={value}
			  disabled = {disabledTextInput ? 'disabled' : ''}

              autosize={{ minRows: 1, maxRows: 6 }}
            />
          )}

          <div className="chat-input__actions">
		  {/**            <UploadField
              onFiles={onSelectFiles}
              containerProps={{
                className: "chat-input__actions-upload-btn"
              }}
              uploadProps={{
					accept: '.pdf,.doc,.docx,.rtf,.zip,.rar',
              }}
            >
              <Button type="link" shape="circle" icon="camera" disabled= {disabledTextInput ? 'disabled' : ''}/>
		  </UploadField>**/}
            {isLoading ? (
              <Button type="link" shape="circle" icon="loading" />
            ) : isRecording || value || attachments.length ? (
              <Button
                onClick={sendMessage}
				disabled= {disabledTextInput ? 'disabled' : ''}
                type="link"
                shape="circle"
                icon="check-circle"
              />
            ) : (
              <div className="chat-input__record-btn">
			  {/**                <Button
				  disabled= {disabledTextInput ? 'disabled' : ''}
                  onClick={onRecord}
                  type="link"
                  shape="circle"
                  icon="audio"
			  /> */}
              </div>
            )}
          </div>
        </div>
        {attachments.length > 0 && (
          <div className="chat-input__attachments">
            <UploadFiles
              removeAttachment={	removeAttachment}
              attachments={attachments}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
}
);
ChatInput.whyDidYouRender = true;
ChatInput.displayName = 'ChatInput';
ChatInput.propTypes = {
  className: PropTypes.string
};

export default ChatInput;
