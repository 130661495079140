import {  messagesApi } from 'chatSource/utils/api'; //dialogsApi, userApi,
//2022import React, { useState, useEffect,useCallback,useContext } from 'react';
//2022import { openNotification } from 'chatSource/utils/helpers';
//2022import { Button, notification } from 'antd-v3';

//2022import { useAppState,AppContext } from "chatSource/redux/reducers/dialogs";

//2022import { dialogsActions, messagesActions } from 'chatSource/redux/actions'; //

import { startMsgId } from 'chatSource/utils/consts';

//import { dialogsActions } from 'chat/redux/actions';
// Это экшены работы со списками диалогов
const Actions = {
  setMessagesIsLoading: bool => ({
    type: "MESSAGES:SET_IS_LOADING",
    payload: bool
  }),
  updateReadedStatus: ({ userId, dialogId }) => ({ // не используется вроде
    type: 'DIALOGS:LAST_MESSAGE_READED_STATUS',
    payload: {
      userId,
      dialogId,
    },
  }),
  

//////////////////////////////////////////////////////////////////////////////////////////////////  
  messagesClearHighLight: (dialogId) => ({
		type: 'MESSAGES:CLEAR_HIGHLIGHT',
		payload: {
		  dialogId,
		},
	  
  }),
//////////////////////////////////////////////////////////////////////////////////////////////////
  setMessages: items => ({ // 
    type: "MESSAGES:SET_ITEMS",
    payload: items
  }),

  appendMessages: items => ({ // 
    type: "MESSAGES:APPEND_ITEMS",
    payload: items
  }),


  setUsers: items => ({ // 
    type: "MESSAGES:SET_USERS",
    payload: items
  }),


 // addMessage: message => (dispatch, getState) => {
  addMessage: (message,dispatch,state) =>  {
  //  const { dialogs } = getState();
	const currentChatType = state.currentChatType;
	const currentChatDoc = state.currentChatDoc;
// тут приходят из 
// тут добавляется в диалог, который сейчас активный
    if ((currentChatType == message.DIALOG_TYPE) && (currentChatDoc == message.DIALOG_DOC_ID) ) {
      dispatch({
        type: "MESSAGES:ADD_MESSAGE",
        payload: message
      });
	  // тут надо бы в localstorage загрузить
	  var msgs = localStorage.getItem('msgs'+'_'+state.data.id);
	  msgs = JSON.parse(msgs);
	  var id = 0;
	  // тут у нас если не загружено еще список сообщений текущего диалога, то ничего тут не будет
	  //и выше в стейт тоже не добавится
 	  if (msgs) {
		  if (msgs[message.DIALOG_ID]) {	
			// тут мы если нашли закешированный диалог в localStorage
			msgs[message.DIALOG_ID].MESSAGES.push(message);
			
			// надо поставить maxId
//		    var id = Math.max.apply(Math, msgs[message.DIALOG_ID].MESSAGES.map(function(o) { return o.MSG_ID; }));
		    // И сохраним его в хранилище			
			localStorage.setItem('msgs'+'_'+state.data.id,JSON.stringify(msgs));
			
			//id = currentDialog.LAST_MESSAGE.LAST_MSG_ID;
		  }
			
	  } 
	  
	  
	  
    }
  },
  //dispatch =>
  fetchSendMessage: ({ text, dialogType, dialogDocId, userId, attachments, chatsocket, isCreating }) =>  { //, creatingType, creatingId 
	  // сделаем объект для отсылки
	  var o = new Object();
	  o.text = text;
	  o.dialogType = dialogType;
	  o.dialogDocId = dialogDocId;
//	  o.dialogId = dialogId;
	  o.userId = userId;
	  o.attachments = attachments;
	  // теперь сдлеаем признак что создается новый чат
	  if (isCreating) {
		  o.isNew = {};
		  //o.isNew.type = creatingType;
		  //o.isNew.docId = creatingId;
	  }
    return messagesApi.send(o, chatsocket);
  },
  setIsMessagesError: bool => ({
    type: "MESSAGES:SET_IS_ERROR",
    payload: bool
  }),
  //dispatch =>
  
  
  removeMessageByIdInt: (id) =>  ({
            type: "MESSAGES:REMOVE_MESSAGE",
            payload: id
  }),
  removeMessageById: (id,dispatch) =>  {
    if (window.confirm("Вы действительно хотите удалить сообщение?")) {
      messagesApi
        .removeById(id)
        .then(({ data }) => {
			dispatch(Actions.removeMessageByIdInt(id));
			
        })
        .catch(() => {
          dispatch(Actions.setMessagesIsLoading(false));
        });
    }
  },
  markReaded: (ids,dispatch,did,uid) => {
    //if (window.confirm("Вы действительно хотите удалить сообщение?")) 
	
	{
      messagesApi
        .markReaded(ids,did,uid)
        .then(({ data }) => {
			// тут у нас пришло ОК в колбэке.
          dispatch({
            type: "MESSAGES:REMOVE_MESSAGES",
            payload: ids
          });
		  // вот тут вот надо локально в localStorage скопировать из state или такую ж манипуляцию провести
		  var msgs = localStorage.getItem('msgs'+'_'+uid); // тут массивоподобный объект строк
		  msgs = JSON.parse(msgs);
		  
		function markMsgReaded(items) {
			function zzz(m) {
				
				return m.map(
					function (item) 	{
						item.MSG_SEEN = 1
						return item

					}
				)
				
				
			}
			items.MESSAGES = zzz(items.MESSAGES);
			
				return items
			
		}
		  
		  msgs[did]=markMsgReaded(msgs[did]); //TODO тут наверно излишне присваивание, тк манипуляция именно над этим объектом
			  msgs = JSON.stringify(msgs);
			  localStorage.setItem('msgs'+'_'+uid,msgs);
		  
        })
        .catch((error) => {
          dispatch(Actions.setMessagesIsLoading(false));
        });
    }
  }, //dispatch =>
  // Остались параметры такие ж. тк там ИД диалога выбирается из локальной базы
  fetchMessages: (dialogId,offset,uId,dispatch) =>  {
	  
    dispatch(Actions.setMessagesIsLoading(true));
	
    messagesApi
      .getAllByDialogId(dialogId,uId,offset)
      .then(({ data }) => {
		  // тут мы получили сообщения. надо найти ключ в localStorage и если он есть - дополнить. если нет - добавить
		  //TODO тут не все так гладко. могут быть разные ситуации
		  var msgs;// = localStorage.getItem('msgs'+'_'+uId); // тут массивоподобный объект строк
		  var unreaded;
		  var unreadedHighlight;
		  

			  //msgs = JSON.parse(data);
			  unreaded = data.UNREADED;
			  delete data.UNREADED;
			  unreadedHighlight = data.UNREADEDOTHERSIDE;
			  delete data.UNREADEDOTHERSIDE;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
			  // тут у нас в unreaded список ИД сообщений диалога - непрочитанных
			  // надо пройти по массиву сообщений и проставить им признак непрочитанностиы
			  if (unreaded.length !==0) {
				for (var iz=0; iz< data.MESSAGES.length;iz++) {
					//console.log(msgs[dialogId].MESSAGES[iz].id)
				   if (unreaded.includes(data.MESSAGES[iz].MSG_ID)) {
					data.MESSAGES[iz].MSG_SEEN=0;
					//console.log('!!!')
				   }

				}			  
			  } else 
			  {
				  // тут у нас массив unreaded пустой. значит в диалоге нет никаких непрочитанных сообщений
					function markMsgReaded(items) {
						function zzz(m) {
							
							return m.map(
								function (item) 	{
									item.MSG_SEEN = 1
									return item

								}
							)
							
							
						}
						items.MESSAGES = zzz(items.MESSAGES);
						
							return items
						
					}
					  
 				    data=markMsgReaded(data); //TODO тут наверно излишне присваивание, тк манипуляция именно над этим объектом

		  
			  }
			  
			// и поставим признак тем, которые надо подсветить как непрочитанные с той стороны
			  if (unreadedHighlight.length !==0) {
				for (var iz=0; iz< data.MESSAGES.length;iz++) {
					//console.log(msgs[dialogId].MESSAGES[iz].id)
				   if (unreadedHighlight.includes(data.MESSAGES[iz].MSG_ID)) {
					data.MESSAGES[iz].HIGHLIGHT=1;
					//console.log('!!!')
				   }

				}			  
			  }	
			  else 
			  {
				  // тут у нас массив unreadedHighlight пустой. значит в диалоге нет никаких непрочитанных сообщений
					function markMsgReaded(items) {
						function zzz(m) {
							
							return m.map(
								function (item) 	{
									item.HIGHLIGHT = 0
									return item

								}
							)
							
							
						}
						items.MESSAGES = zzz(items.MESSAGES);
						
							return items
						
					}
					  
 				    data=markMsgReaded(data); //TODO тут наверно излишне присваивание, тк манипуляция именно над этим объектом

		  
			  }
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


		  
		  
		  if (offset == startMsgId) {
				  dispatch(Actions.setMessages(data)); // добавляем в текущий стейт сообщения

		  } else {
				dispatch(Actions.appendMessages(data.MESSAGES));
			  
		  }
		  
		  dispatch(Actions.setMessagesIsLoading(false));		
		  
		  return;
		  


        dispatch(Actions.setMessages(msgs[dialogId]));
			  // и теперь назад
			  msgs = JSON.stringify(msgs);
			  localStorage.setItem('msgs'+'_'+uId,msgs);
		dispatch(Actions.setMessagesIsLoading(false));		
		
      })
      .catch(() => {
		  dispatch(Actions.setIsMessagesError(true));
          dispatch(Actions.setMessagesIsLoading(false));
      });
  },
////////////////////////////////////////////////////////////////////////////////////////////////////////////
  setAttachments: items => ({
    type: "ATTACHMENTS:SET_ITEMS",
    payload: items
  }),
  removeAttachment: file => ({
    type: "ATTACHMENTS:REMOVE_ITEM",
    payload: file
  }),
  clearAttachments: items => ({
    type: "ATTACHMENTS:CLEAR_ITEMS",
    payload: []
  }),
  
};

export default Actions;
 