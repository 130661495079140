import React, { useState, useEffect,useCallback } from 'react'; //,useContext
//import { connect } from 'react-redux';
import { filesApi } from 'chatSource/utils/api';
//import socket from 'chat/core/socket';

import {socket,useSocket } from 'chatSource/context/socket'; //SocketContext,

import { ChatInput as ChatInputBase } from 'chatSource/components';
//import { Emoji } from 'emoji-mart';
//import reactStringReplace from 'react-string-replace';


import { messagesActions } from 'chatSource/redux/actions'; //dialogsActions,

import { useAppState } from "chatSource/redux/reducers/dialogs";
import { useAppMessagesState } from "chatSource/redux/reducers/messages";

const ChatInput = ({setScrollPos}) => {
	

	   const [appstate, dispatch] = useAppState();
	   const [messagesstate, messagesDispatch] = useAppMessagesState();
	   
   	   
	   //const socket = useContext(SocketContext);
	//const socket = undefined;
	//const connected = undefined;
	   

	   //const socket = null;
//  const {
//    attachments,
//    fetchSendMessage,
//    setAttachments,
//    removeAttachment,
//    //user,
//  } = props;

  const [value, setValue] = useState('');
  const [disabledTextInput, setdisabledTextInput] = useState(false);
  const [isRecording, setIsRecording] = useState('');
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [emojiPickerVisible, setShowEmojiPicker] = useState(false);
  const [isLoading, setLoading] = useState(false);



  var attachments = messagesstate.attachments;
  var fetchSendMessage = messagesActions.fetchSendMessage;
  var setAttachments = messagesActions.setAttachments;
  var removeAttachment = messagesActions.removeAttachment;
  
  const     currentChatType = appstate.currentChatType;
  const     currentChatDoc = appstate.currentChatDoc;
  
//alert(appstate.isCreating);
  //const chatconnected = socket.connected;
  //const chatsocket = socket;
   const [chatsocket,chatconnected] =  useSocket(appstate.data.id,1);

  
 const zzz = (a) => {
	 //alert(JSON.stringify(a));
	 messagesDispatch(removeAttachment(a))
	 
 }
 var nulledCurrentDialogId = undefined;
 var lastBlockFlag = disabledTextInput;
 
 var isCreating = appstate.isCreating;
 
 console.log('is creating 0'+isCreating);
 
 if (!isCreating) {  // если открывается старый диалог
		 if ((appstate.currentChatDoc == undefined) || (appstate.currentChatDoc === -1)) {
			 nulledCurrentDialogId = 'Не выбран диалог';
			 // собираем окончательный текст
			 nulledCurrentDialogId = nulledCurrentDialogId ? nulledCurrentDialogId :  disabledTextInput ? 'Ошибка подключения к серверу' : "Введите текст сообщения…";
			// и флаг блокировки 
			lastBlockFlag = nulledCurrentDialogId ? 'disabled' : disabledTextInput ? 'disabled' : '';
		 }
 } else {
	 // если диалог создается
	 nulledCurrentDialogId = 'Введите текст сообщения…';
	 //lastBlockFlag=false; 
	 //alert(lastBlockFlag);
 }
 console.log(socket.connected);
 if (chatconnected) {

	 nulledCurrentDialogId = nulledCurrentDialogId ? nulledCurrentDialogId :  disabledTextInput ? 'Ошибка подключения к серверу' : "Введите текст сообщения…";
	 
 } else {
	 nulledCurrentDialogId = 'Ошибка подключения к серверу';
 }

  window.navigator.getUserMedia =
    window.navigator.getUserMedia ||
    window.navigator.mozGetUserMedia ||
    window.navigator.msGetUserMedia ||
    window.navigator.webkitGetUserMedia;



  const handleOutsideClick = (el, e) => {
    if (el && !el.contains(e.target)) {
      setShowEmojiPicker(false);
    }
  };

	useEffect(() => {
		console.log(socket.connected);
		console.log('aaaaaaaaaaaaaaaaaaaaa')
		
	},[socket.connected]);


	// снизу перенесли и eslint перестал ругаться
  useEffect(() => {
//2021    const el = document.querySelector('.chat-input__smile-btn');
//2021    document.addEventListener('click', handleOutsideClick.bind(this, el));
//2021    return () => {
//2021      document.removeEventListener('click', handleOutsideClick.bind(this, el));
//2021    };
  }, []);


  useEffect(() => {  // тут тоже вроде в одном экземпляре
	  //alert('s'+chatconnected);
     setdisabledTextInput(!chatconnected);
	 if (!chatconnected) {

		// nulledCurrentDialogId = 'Ошибка подключения к серверу';
		 	 //alert(nulledCurrentDialogId+' '+chatconnected)		 
	 }
  }, [chatconnected]);

  useEffect(() => {
//	  alert(chatconnected);
  },[chatconnected]);


  const toggleEmojiPicker =  useCallback(
  () => {
    setShowEmojiPicker(!emojiPickerVisible);
  }
  ,[]);

  const onRecord =  useCallback(
  () => {
	  alert('Временно не неализовано!!');
	  return;
    if (navigator.getUserMedia) {
      navigator.getUserMedia({ audio: true }, onRecording, onError);
    }
  },[]);

  const onRecording =  useCallback(
  stream => {
    const recorder = new MediaRecorder(stream);
    setMediaRecorder(recorder);

    recorder.start();

    recorder.onstart = () => {
      setIsRecording(true);
    };

    recorder.onstop = () => {
      setIsRecording(false);
    };

    recorder.ondataavailable = e => {
      const file = new File([e.data], 'audio.webm');
      setLoading(true);
      filesApi.upload(file).then(({ data }) => {
        sendAudio(data.file._id).then(() => {
          setLoading(false);
        });
      });
    };
  },[]);

  const onError = err => {
    console.log('The following error occured: ' + err);
  };


  const addEmoji = 
  useCallback(
  ({ colons,native,unified }) => {
	 var  zzz = (value + ' ' + colons).trim();
    setValue(zzz);
  },[]);


  const setVal = 
  useCallback( 
  e => {
	  console.log('is creating in setval'+isCreating);
    setValue(e.target.value);
//	alert(e.target.value);
//	alert(value); 
  }
  //,[value] вот тут все ж это не нужно. и при компиляции ругается и вроде как видит value
  ); // будет пересоздаваться на каждое изменение value и соответственно видеть послежнее значение


  const handleSendMessage =  useCallback(
  
  e => {
	  //alert('is creating in handleSendMessage'+isCreating);
    //socket.emit('DIALOGS:TYPING', { dialogId: currentDialogId, user });
    if (e.keyCode === 13) {
      sendMessage();
	  setScrollPos(999999999)
     }
  },[value,attachments,appstate.isCreating]); // будет пересоздаваться на каждое изменение value и соответственно видеть послежнее значение



  const clickEmo =  useCallback(
  ({emoji,event}) => {
	console.log(emoji);
    console.log(event);	
  },[]);
  
  const sendAudio =  useCallback(
  audioId => {
    return fetchSendMessage({
      text: null,
	  dialogType: currentChatType,
	  dialogDocId: currentChatDoc,
	  userId: appstate.data.id,
      attachments: [audioId],
		chatsocket,
		isCreating: isCreating//,
		//creatingType: appstate.creatingType//,
		//creatingId: appstate.creatingId

    });
  },[value]);

  const sendMessage = 
  useCallback(
  () => {
	  //alert(appstate.isCreating);
	  //return;
	if (!chatconnected) {
		alert('Ошибка соединения с сервером!');
		return;
		
	}
    if (isRecording) {
      mediaRecorder.stop();
    } else if (value || attachments.length) {
      fetchSendMessage({
        text: value,
	  dialogType: currentChatType,
	  dialogDocId: currentChatDoc,

		userId: appstate.data.id,
        attachments: attachments.map(file => file.uid),
		chatsocket,
		isCreating: isCreating//,
		//creatingType: appstate.creatingType//,
		//creatingId: appstate.creatingId
      });
      setValue('');
      messagesDispatch(setAttachments([]));
	  // сбросим признак создания нового

	  console.log('is creating in sendmessages'+isCreating);
	  
    }
  },[value,attachments,appstate.isCreating]); // будет пересоздаваться на каждое изменение value и соответственно видеть послежнее значение


  const onHideRecording =  useCallback(
  () => {
    setIsRecording(false);
  },[]);

  const onSelectFiles =  useCallback(
  async files => {
    let uploaded = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const uid = Math.round(Math.random() * 1000);
      uploaded = [
        ...uploaded,
        {
          uid,
          name: file.name,
          status: 'uploading',
        },
      ];
	  // TODO если ошибка - ругаетя в консоль и не выбирает еще раз файл. переделать
      messagesDispatch(setAttachments(uploaded));
      // eslint-disable-next-line no-loop-func
      await filesApi.upload(file).then(({ data }) => {
        uploaded = uploaded.map(item => {
          if (item.uid === uid) {
            return {
              status: 'done',
              uid: data.file.id,
              name: data.file.filename,
              url: data.file.url,
            };
          }
          return item;
        });
      }).catch(function (error) {
        // handle error
       console.log(error);
       uploaded = [
         {
           uid: -1,
           name: file.name,
           status: 'error',
         },
       ];
       messagesDispatch(setAttachments(uploaded));
	   
     }) ;
    }
    messagesDispatch(setAttachments(uploaded));
  },[]);

//alert(appstate.isCreating);
  if ((!appstate.isCreating)  && (!currentChatDoc)) {
    return null;
  }
  
  if (messagesstate.isMessagesLoading) {
	  return null;
  }
  
  if (messagesstate.isMessagesError) {
	  return null;
  }
  

//	alert(attachments.length);
  
  return (
    <ChatInputBase
      value={value}
      setValue={setVal}
      emojiPickerVisible={emojiPickerVisible}
      toggleEmojiPicker={toggleEmojiPicker}
      addEmoji={addEmoji}
	  clickEmo={clickEmo}
      handleSendMessage={handleSendMessage}
      sendMessage={sendMessage}
      onSelectFiles={onSelectFiles}
      attachments={attachments}
      isRecording={isRecording}
      onRecord={onRecord}
      onHideRecording={onHideRecording}
      isLoading={isLoading}
	  dispatch={dispatch}
	  messagesDispatch={messagesDispatch}
      removeAttachment={zzz}
	  
	  disabledTextInput={lastBlockFlag}
	  nulledCurrentDialogId={nulledCurrentDialogId}
	  
    />
  );
};

ChatInput.displayName = 'ChatInputConainer';
ChatInput.whyDidYouRender = true;


export default //connect(
//dialogs,
//dialogs,
//, user
//user: user.data,
//  ({ attachments }) => ({
    //dialogs: dialogs,
//    attachments: attachments.items,
    
//  }),
//  { ...dialogsActions, ...attachmentsActions },
//)(
ChatInput
//)
;
