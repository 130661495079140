import React, { Fragment } from "react";
import PropTypes from "prop-types";
import distanceInWordsToNow from "date-fns/distance_in_words_to_now";
import ruLocale from "date-fns/locale/ru";
import isToday from 'date-fns/is_today';
import format from 'date-fns/format';

const getMessageTime = createdAt => {
  if (isToday(createdAt)) {
    return format(createdAt, 'hh:mm');
  } else {
    return format(createdAt, 'dd.MM.yyyy');
  }
};
//		distanceInWordsToNow(d, { addSuffix: true, locale: ruLocale })}
const Time = ({ date }) => {
	var d = new Date();
	d.setTime(date);
  return <Fragment>
    {getMessageTime(date)}
		

  </Fragment>
};

Time.propTypes = {
  date: PropTypes.number
};

export default Time;
