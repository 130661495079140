import React from "react";
import PropTypes from "prop-types";
import readedSvg from "chatSource/assets/img/readed.svg";
import noReadedSvg from "chatSource/assets/img/noreaded.svg";
import X from "chatSource/assets/img/x.svg";
import { dialogsActions } from 'chatSource/redux/actions';
//import store from 'chat/redux/store';


import { useAppState } from "chatSource/redux/reducers/dialogs";

//message__icon-readed--no
const DialogCloseButton = ({ isMe, isReaded, did }) =>
	{
		const [state, dispatch] = useAppState();
  return (
  <div className="message__icon-closebutton">
      <img  src={X} alt="Close icon" onClick={(e)=> {
		   // alert('123123');
			//return
            dispatch(dialogsActions.isDeleting(true)) 		  
		    // сначала поставим ИД диалога пустым иначе статус будет неверно отображаться
			// тут всегда стейт изменится. хотя при удалении оного диалога , потом другого стейт будет всегда меняться с undefined to undefined
			dialogsActions.setCurrentDialogId(undefined, dispatch);
			dialogsActions.setCurrentDialogParams(undefined, undefined, dispatch);
			
			
		     dispatch(dialogsActions.deleteDialog(did));
			 //store.dispatch(dialogsActions.isDeleting(false))  тут не выставляем флаг обратно. тк линк еще отработает
		  }}   />
   </div>
   )
	};

DialogCloseButton.propTypes = {
  isMe: PropTypes.bool,
  isReaded: PropTypes.bool
};

export default DialogCloseButton;
