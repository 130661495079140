import { axios } from "chatSource/core";
import mainRoute from 'chatSource/routes'; 

export default {
  signIn: postData => axios.post("/user/signin", postData),
  signUp: postData => axios.post("/user/signup", postData),
  verifyHash: hash => axios.get("/user/verify?hash=" + hash),
  getMe: (uid) => axios.get(mainRoute+"/user/me?uid="+uid),
  findUsers: query => axios.get(mainRoute+"/user/find?query=" + query)
};
