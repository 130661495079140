import React, {useContext, useState, useEffect} from 'react';
import io from "socket.io-client";
//import { SOCKET_URL } from "config";
//https://dev.to/bravemaster619/how-to-use-socket-io-client-correctly-in-react-app-o65
//export const socket = io.connect(window.location.origin.replace("3000", "3003"));
const socket = io(window.location.origin,

{ 
	path: '/chat.io',
	//transports: ["websocket"],
	//reconnection: false
	reconnection: true, rejectUnauthorized: false 


}); //'/chat.io'




export {socket};

//const mainsocket = io(window.location.origin,{path: '/main.io'}); //'/chat.io'
export const ChatSocketContext = React.createContext(socket);
export const chatsocketconnected = React.createContext(false);
//export const ChatSocketConnectedContext = React.createContext(false);
//export const MainSocketContext = React.createContext(mainsocket);



export const useSocket = (uId,zzz) => {
	console.log('starting hook '+zzz);
  const [chatconnected, setChatConnected] = useState(false); // стейт будет всегда некорректный
  //const [mainconnected, setMainConnected] = useState(false);
  //const [connected, setConnected] = useState(false);
  const chatsocket = useContext(ChatSocketContext);
  //const mainsocket = useContext(MainSocketContext);
  //const socket = io(window.location.origin,{path: '/chat.io'}); //'/chat.io'
  
  /** First of all it's necessary to handle the socket io connection */
  useEffect (() => {
	//  alert('conneced '+chatconnected);
	 // тут по какой то причине срабатывает дважды
	 // тут все корректно в принципе. сколько раз вызван хук в разных местах, столько раз и выполнено и столько же стейтов connected
	 
	 // вот это наверно суперкриво, но работает
	 
	 const z1 = () => {
		 console.log(zzz);
		 setChatConnected(false); // false socket.connected
		 
	 }
	 const z2 = () => {
		 //alert('connected '+zzz);
		  setChatConnected(true); //true  socket.connected
		 
	 }
	  //if (chatconnected) 
	  {
		//  alert('!!!!!!!!!!!!!!!!!!!!! '+chatsocket.id);
//		chatsocket.emit('USER:ONLINE', uId);
		//alert('init'+chatconnected);
		// тут все работает наконец и вроде как в одном экзэмпляре!!!
		//alert('effec '+ chatsocket.connected );
			chatsocket.on('disconnect', 
			// только так виден нужный стейт в функции что по ссылке
			z1
//			e => 
//			setChatConnected(false)
			//handleNewMessageRef.current
			//newMessage
			); 
			chatsocket.on('connect', 
			// только так виден нужный стейт в функции что по ссылке
			z2
//			e => 
//			setChatConnected(true)
			//handleNewMessageRef.current
			//newMessage
			); 
		
	  }  
	return function cleanup() {
      //  alert('learnup socket1 hook');
		//alert('learnup socket1');
		chatsocket.off('disconnect',z1);
		chatsocket.off('connect',z2);
    }	  
	  
	   
  }, [ ] ); //uId, chatsocket, chatconnected тут именно с пустым массивом
  


  // тут именно socket.connected тк в большом приложении возникает проблема с этим
  useEffect(() => {
    if (uId === null) 
	{
      if (socket !== null) {
        socket.disconnect();
      } 
	//  alert('socket false');
      setChatConnected(socket.connected);  //false socket.connected
    } else 
	{
//alert('connecting');
      if (chatsocket !== null) 
	  {
	   if (!chatconnected) 	    //chatconnected socket.connected
	   {
				//chatsocket.connect();
				setChatConnected(socket.connected);  //socket.connected
	   } else {
			setChatConnected(socket.connected); // true socket.connected
      }
	 // alert('socket true');
//      setChatConnected(true);
    }}

/*	{
      if (mainsocket !== null) {
	   if (!mainsocket.connected) 	  
				mainsocket.connect();
      } else {
        //socketRef.current = io();
		// тут именно так!!! только в таком случае ссылка будет нормальонй
//		socketRef.current =  io(window.location.origin.replace("3000", "3003")); // это скорее всего коннект
      }
      setMainConnected(true);
    }*/
	
	return function cleanup() {
        console.log('learnup socket1');
		//alert('learnup socket1');
    }	
  }, [uId,chatsocket]);
  
  //, mainsocket, mainconnected 
  return [chatsocket,chatconnected];
};
