import React, {  useEffect, Profiler } from 'react';
import  useAppStateContext, { AppContext } from "chatSource/redux/reducers/dialogs"; //, useAppState
import  useAppMessagesContext, { AppMessagesContext } from "chatSource/redux/reducers/messages"; //, useAppMessagesState
//import {  useSocket} from 'chatSource/context/socket'; //socket, SocketContext,
import { BrowserRouter as Router,Route, Redirect, Switch  } from "react-router-dom"; //,useParams useHistory, ,  useLocation

import { Auth, HomeContainer } from "chatSource/pages"; //Auth, 
import { dialogsActions } from 'chatSource/redux/actions'; //, messagesActions
import mainRoute from 'chatSource/routes'; 


import "chatSource/styles/index.scss";

//{onRenderCallback,uId}
//может быть в параметрах еще такие свойства. это чтоб открыть либо чат нуюный либо создать новый документ
//CHAT_ID
//DOC_ID
//DOC_TYPE
const ChatWrapper = (props) => {
  const [state, dispatch] = useAppStateContext();
  const [messagesstate, messagesDispatch] = useAppMessagesContext();
  const uId = props.uId;
	//alert(props.location.pathname);
	const zzz = props.CREATE_CHAT;
	
	const qqq = props.qqq;

  //alert('is creating '+state.isCreating);  
 // let history = useHistory();
 // alert((props.CHAT_ID));

//   const location = useLocation();
  //alert(JSON.stringify(props));
//  const chatsocket = socket;
//  const chatconnected = socket.connected;
//  const [chatsocket,chatconnected] =  useSocket(uId,2);

  
//  if (state.data)
//		alert('chatw'+state.data.id+uId);	
//		
//	console.log('chatw'+uId);	
const z = {id: uId};


	
	//if (location.state?.loc) {
		
		console.log('-----------------------------------------------!!!');
	console.log('props1 = '+qqq);
	console.log('props2 = '+zzz);
		console.log(uId);
	
	//}
	var mustRedirect = undefined;
	if ((props.documentId) && (props.documentType) && (props.uId)) {
		//http://localhost:3002/chatt/dialog/133/98155
		mustRedirect = '/chatt/dialog/'+props.documentType+'/'+props.documentId;
	}
	
   useEffect(() => {
	  dispatch(dialogsActions.setUserData(z)); 
	   //alert('u');
	return function cleanup() {
   //     alert('learnup chatwrapper');
		//alert('learnup socket1');
    }	  
	   
   }, [uId]);   
//   alert('props');
//   alert(JSON.stringify(props));
   //var v = '';
   let c = '';
   if (props.CREATE_CHAT) {
         c = "/chatt" + "/create/" + props.CREATE_CHAT.DOC_ID + "/" + props.CREATE_CHAT.DOC_TYPE;
   }
   
   if (props.EXIST_CHAT) {
		var r = "/chatt" + "/dialog/" + props.EXIST_CHAT.CHAT_ID;
   }
   console.log('j' + state.isAuth);
//alert('exist_chat '+JSON.stringify(props.EXIST_CHAT));
//alert(props.DOC_ID);        
//alert(props.CHAT_ID);
//http://localhost:3004/chatt/create/72785/133
//TODO!!! тут надо в parseInt проверять действительно ли там int   state= {{loc: location}}
	return (
	    <Router>
		
		<Switch>
				
			// тут типа редирект должен быть если ИД чата пусто. 
			{mustRedirect  ? <Redirect exact from="/" to={mustRedirect} /> : null}
	
			

			<AppContext.Provider value={[state, dispatch]}>	  		
			<AppMessagesContext.Provider value={[messagesstate, messagesDispatch]}>	  		

					<Route
					  exact
					  path={[mainRoute+"/signin", mainRoute+"/signup", mainRoute + "/signup/verify"]}
					  //component={Auth}
						  render={() => { 
								console.log('signin'); 
								return <Auth />

						}} 
					/>
					<Route
					  exact
					  path={[mainRoute + "/dialog/:type/:id",mainRoute]}
					  
					  render={({match}) => (
					  
					  state.isAuth ? 


					<Profiler id="Home" onRender={props.onRenderCallback}>
					

					{/**					<HomeContainer currentDialogId = {match.params.id ? parseInt(match.params.id) : -1}   {...match} /> */}
					<HomeContainer 
					currentChatType = {match.params.type ? parseInt(match.params.type) : undefined}   
					currentChatDoc = {match.params.id ? parseInt(match.params.id) : undefined}   
					
					{...match} /> 

					
					</Profiler>

					  
					  
					  : null //<Redirect to="/chat/signin" />
					  
					  )} 
					/>
					
					<Route
					  path={[mainRoute+"/create/:docid/:type"]}
					  
					  render={({match}) => {
					  
					  return state.isAuth ? 


					<Profiler id="Home" onRender={props.onRenderCallback}>
					

					{/**<HomeContainer currentDialogId = {match.params.id ? parseInt(match.params.id) : -1}   {...match} />  */}
					<HomeContainer 
					currentChatType = {match.params.type ? parseInt(match.params.type) : undefined}   
					currentChatDoc = {match.params.id ? parseInt(match.params.id) : undefined}   

					{...match} /> 

					
					</Profiler>

					  
					  
					  : null //<Redirect to="/chat/signin" />
					  
					  }} 
					/>
					<Route
					  path={mainRoute+"/dialog*"}
					  
					  render={() => (
					  <Auth />
					  
					  )} 
					/>
			</AppMessagesContext.Provider>		  						
			</AppContext.Provider>		  			
		</Switch>

    </Router>


	)
	
}
ChatWrapper.whyDidYouRender = true

export default ChatWrapper;

export {
  ChatWrapper
};
