 import { useReducer, useContext, createContext }  from "react";

const initialState = {
	// вот это вот можно вынести в отдельный редь.сер
  messages: {},
  unreaded: [],// список ИД непрочитанных в текущем диалоге с учетом текущего пользователы. те тех, которые оппонент не прочитал
  attachments: [],
    isMessagesLoading: false,
  isMessagesError: false,
  
};

function markMsgReaded(items) {
	function zzz(m) {
		
		return m.map(
			function (item) 	{
                item.MSG_SEEN = 1
				return item

			}
		)
		
		
	}
	items.MESSAGES = zzz(items.MESSAGES);
	
		return items
	
}

//= initialState
const reducer = (state , action) => {
	console.log('reducer');
	console.log(action);
  switch (action.type) {
    case 'MESSAGES:CLEAR_HIGHLIGHT':
      // вот тут мы создаем НОВЫЙ объект. иначе не понимает редакс, что что то изменилось	  
	  if (Object.keys(state.messages).length !==0) {
			  var itemss = Object.assign({},state.messages); 
			  //TODO может утечка?
				itemss.MESSAGES = itemss.MESSAGES.map(messages => {
					messages.HIGHLIGHT = 0;
				  return messages;
				});
			  //z.items.MESSAGES.push(payload)
			  return {
				  ...state,
				  messages: itemss 
				  
			  };
	  } else {
		  return state ;// если не вернем, то выполнтся слежующий
		  
	  }

	  // эта часть метит в диалоге все сообщения как ПРОЧИТАННЫЕ
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case 'MESSAGES:SET_IS_LOADING':
      return {
        ...state,
        isMessagesLoading: action.payload,
      };
	  
    case 'MESSAGES:ADD_MESSAGE':
      //var z = state
      // вот тут мы создаем НОВЫЙ объект. иначе не понимает редакс, что что то изменилось	  
	  if (Object.keys(state.messages).length !==0) {
			  var itemss = Object.assign({},state.messages); 
			  //TODO может утечка?
			  itemss.MESSAGES = itemss.MESSAGES.concat([action.payload]);
			  //z.items.MESSAGES.push(payload)
			  return {
				  ...state,
				  messages: itemss 
				  
			  };
	  } else {
		  return state ;// если не вернем, то выполнтся слежующий
		  
	  }
    case 'MESSAGES:SET_ITEMS':
      return {
        ...state,
        messages: action.payload,
        isMessagesLoading: false,
		isMessagesError: false, // если устанавливаем итемы, то точно нет ошибки
      };

    case 'MESSAGES:APPEND_ITEMS':
      return {
        ...state,
		messages : {
			...state.messages,
			MESSAGES: action.payload.concat((state.messages.MESSAGES ? state.messages.MESSAGES : [])),
			
			
		}

      };



    case 'MESSAGES:SET_USERS':
      return {
        ...state,
		messages : {
			USERS: action.payload.concat((state.messages.USERS ? state.messages.USERS : []))
			
		}

      };


    case 'DIALOGS:LAST_MESSAGE_READED_STATUS':
      return {
        ...state,
        messages: state.messages.MESSAGES.map(message => {
          if (message.dialog.DIALOG_ID === action.payload.dialogId) {
            message.readed = true;
          }
          return message;
        }),
      };
    case 'MESSAGES:REMOVE_MESSAGE':
      return {
        ...state,
			messages : {
				...state.messages,
				MESSAGES: state.messages.MESSAGES.filter(message => message.MSG_ID !== action.payload)
				
			}

      };
    case 'MESSAGES:REMOVE_MESSAGES':
      return {
        ...state,
        //items: state.items.MESSAGES.filter(message => message._id !== payload)
		messages:  {... markMsgReaded(state.messages)}
		
		,
      };
    case 'MESSAGES:SET_IS_ERROR':
      return {
        ...state,
        isMessagesError: action.payload,
      };	  
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case "ATTACHMENTS:SET_ITEMS":
      return {
        ...state,
        attachments: action.payload
      };
    case "ATTACHMENTS:REMOVE_ITEM":
      return {
        ...state,
        attachments: state.attachments.filter(item => item.uid !== action.payload.uid)
      };
    case "ATTACHMENTS:CLEAR_ITEMS":
      return {
        ...state,
        attachments: []
      };

    default:
      return state;
  }
};



const useAppMessagesContext = () => {
  return useReducer(reducer, initialState);
};


export const AppMessagesContext = createContext();

export const useAppMessagesState = () => {
  const [state, dispatch] = useContext(AppMessagesContext);
  return [state, dispatch];
};



export default useAppMessagesContext;