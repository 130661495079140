import React, { useState, useRef, useEffect,memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Popover, Button, Icon } from 'antd-v3';
import { Emoji } from 'emoji-mart';
import reactStringReplace from 'react-string-replace';
import {  Link } from "react-router-dom";
import { convertCurrentTime, isAudio } from 'chatSource/utils/helpers';

import  FileSaver  from 'file-saver';
import fileDownload from 'js-file-download';
import Axios from "axios";

import waveSvg from 'chatSource/assets/img/wave.svg';
import playSvg from 'chatSource/assets/img/play.svg';
import pauseSvg from 'chatSource/assets/img/pause.svg';
import { Time, IconReaded, Avatar } from '../';
import { dialogsActions, messagesActions } from 'chatSource/redux/actions'; //attachmentsActions




import { messagesApi } from 'chatSource/utils/api';

import './Message.scss';

const prettyBytes = require('pretty-bytes');

const MessageAudio = ({ audioSrc }) => {
  const audioElem = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const togglePlay = () => {
    if (!isPlaying) {
      audioElem.current.play();
    } else {
      audioElem.current.pause();
    }
  };

  useEffect(() => {
    audioElem.current.volume = '0.01';
    audioElem.current.addEventListener(
      'playing',
      () => {
        setIsPlaying(true);
      },
      false,
    );
    audioElem.current.addEventListener(
      'ended',
      () => {
        setIsPlaying(false);
        setProgress(0);
        setCurrentTime(0);
      },
      false,
    );
    audioElem.current.addEventListener(
      'pause',
      () => {
        setIsPlaying(false);
      },
      false,
    );
    audioElem.current.addEventListener('timeupdate', () => {
      const duration = (audioElem.current && audioElem.current.duration) || 0;
      setCurrentTime(audioElem.current.currentTime);
      setProgress((audioElem.current.currentTime / duration) * 100);
    });
  }, []);

  return (
    <div className="message__audio">
      <audio ref={audioElem} src={audioSrc} preload="auto" />
      <div className="message__audio-progress" style={{ width: progress + '%' }} />
      <div className="message__audio-info">
        <div className="message__audio-btn">
          <button onClick={togglePlay}>
            {isPlaying ? (
              <img src={pauseSvg} alt="Pause svg" />
            ) : (
              <img src={playSvg} alt="Play svg" />
            )}
          </button>
        </div>
        <div className="message__audio-wave">
          <img src={waveSvg} alt="Wave svg" />
        </div>
        <span className="message__audio-duration">{convertCurrentTime(currentTime)}</span>
      </div>
    </div>
  );
};


const z =  ({url}) => {
	alert(url);
	
	
}


const  download =  (url, filename) => {
  Axios.get(url, {
    responseType: 'blob',
  }).then(res => {
    fileDownload(res.data, filename);
  });
}

const Message = memo(({
  MSG_TEXT,
  MSG_DATE,
  DIALOG_ID,
  userId,
  users, // массив пользователей диалога
  isMe,
  MSG_SEEN,
  HIGHLIGHT,
  ATTACHMENTS,
  isTyping,
  MSG_ID,
  onRemoveMessage,
  setPreviewImage,
  mainDispatcher,
  messagesDispatch,
  isDesktopOrLaptop,
  globalUser
}) => {
	const [visible,setVisible] = useState(false);
	//style={{width: '347px', height: '255px'}}>
  const renderAttachment = item => {
		//return null;
	  switch (item.TYPE) {
		 case 'img':
			  return (
	         <div className="message__img_im_msg_media">			  
	  <div className="message__img_page_post_sized_thumbs  clear_fix" style={{width: '200px', height: '255px'}}>
					 <a 
					    key={item.ID}
					    onClick={() => setPreviewImage(item.URL)}
					    aria-label="Картинка" 
					    style={{
							width: '347px', 
							height: '256px',
							backgroundImage: `url(${item.URL})`
							}
							} 
						className="message__img_page_post_thumb_wrap message__img_image_cover  message__img_page_post_thumb_last_column message__img_page_post_thumb_last_row" data-photo-id="482551425_456239331">
					</a>
					 </div>			  
			</div>			  		 
			  
			  );
		case 'audio':
		  return <MessageAudio key={item.ID} audioSrc={item.URL} />;	
		
		case 'video':
		  break;
		case 'doc': 
			  return (

<div className="message__im_msg_media76841">
   <div className="message__att_im_msg_media message__att_im_msg_media_doc">
      <div className="message__att_media_desc message__att_media_desc__doc PostMediaRowWithActionStatusBarSeparator message__im_msg_media_doc_block">
         <div className="message__att_page_doc_row">
            <a className="message__att_page_doc_icon message__att_page_doc_icon1" onClick= {() => download(item.URL,item.FILENAME) } target="_blank"></a> 
            <a className="message__att_page_doc_title" onClick= {() => download(item.URL,item.FILENAME) } target="_blank">{item.FILENAME}</a> 
            <div className="message__att_page_doc_description_row">
               <div className="message__att_page_doc_size">{prettyBytes(item.SIZE)}</div>
               <div className="message__att_page_doc_app"></div>
            </div>
         </div>
      </div>
   </div>
</div>

			  
			  );
		
		  break;
		  
		  
	  }
	  
	  
/*    if (item.TYPE === 'image') { // если картинка
      return (
        <div
          key={item.ID}
          onClick={() => setPreviewImage(item.URL)}
          className="message__attachments-item">
          <div className="message__attachments-item-overlay">
            <Icon type="eye" style={{ color: 'white', fontSize: 18 }} />
          </div>

          <img src={item.URL} alt={item.FILENAME} />
        </div>
      );
    } else 
	{
		if (item.TYPE === 'audio') {
		    return <MessageAudio key={item.ID} audioSrc={item.URL} />;	
			
		}im-mess--text
	}*/
	
      
  };
  var u = users.filter(user => user.ID == userId)[0];
  return (
	<div className= {HIGHLIGHT === 1 ? "message__im-mess-stack message__im-mess-stack_unreaded " :"message__im-mess-stack "} data-peer={MSG_DATE} data-admin=""> 
	   <div className="message__im-mess-stack--photo"> 
          <Avatar  ID={u.ID}  AVATAR={u.AVATAR} FULLNAME={u.FULLNAME }  isSmall={true} />
	   </div> 
	   <div className="message__im-mess-stack--content"> 
		  <div className="message__im-mess-stack--info"> 
			<div className="message__im-mess-stack--pname"> 
			   <a className="message__im-mess-stack--lnk" title="" target="_blank">{u.FULLNAME}</a> 
			   <span className="message__im-mess-stack--tools"> 
				 <a className="_im_mess_link">{<Time date={MSG_DATE} />}</a>
			   </span> 
			</div> 
		  </div> 
		  <ul className="message__ui_clean_list message__im-mess-stack--mess _im_stack_messages"> 
			 <li className="message__im-mess _im_mess _im_mess_76621" aria-hidden="false" data-ts="1626726895" data-msgid="76621" data-peer="1942359">
			   <div className="message__im-mess--actions"> 
				  <span role="link" aria-label="Переслать" className="message__im-mess--forward "></span> 
				  {isDesktopOrLaptop ? 
				  //__im-mess--reply это отдельный класс чтоб работал hover. он в scss в 133 строке
				  <span role="link" aria-label="Удалить" className="message__im-mess--reply __im-mess--reply" 
				  onClick={(e)=> { 
						 e.stopPropagation();
						e.preventDefault(); 

					// TODO тут последний параметр концептуально лишний. но без него никак. потом сделать чтоб брал из куки на строне сервера. или как то иначе
				     messagesApi.removeById(DIALOG_ID,MSG_ID,globalUser).then ( value=> {
						 console.log(value);
						 if (value.data.success == false) {
							 alert(value.data.msg);
						 } else {
							 if (value.data.lastMsg) {
								 // тут надо поменять последнее сообщение в списке диалогов
								 
								 mainDispatcher(dialogsActions.addLastMessageToDialogsList(value.data.lastMsg.DIALOG_ID,value.data.lastMsg));
								 //dialogsActions.updateReadedStatusLocal(cdId,mainDispatcher); //messagesDispatch
							 }
								 // и удалить сообщение из списка приложения
								 messagesDispatch(messagesActions.removeMessageByIdInt(MSG_ID));
							 
						 }
						 ///alert('Пока не реализовано!') 
						 
					 }
				  
				              
				  ).catch (function (e) {
					alert('Ошибка сервера!'  );
					  
					  
				  });
				  
				  
				  }}
				  >
				  
				  
				  </span> 
				  
				  : null}
				  <span role="link" aria-label="Редактировать" className="message__im-mess--edit "></span> 
				  <span role="link" aria-label="Важное сообщение" className="message__im-mess--fav "></span>              
			   </div> 
			   <div className="message__im-mess--check message__fl_l"></div> 
			   <div className="message__im-mess--text message__wall_module _im_log_body">{MSG_TEXT}
			   
			  {ATTACHMENTS && (
				<div >
				  {ATTACHMENTS.map(item => renderAttachment(item))}
				</div>
			  )}
			   
			   
			   </div> 



			   <span role="link" aria-label="Выделить сообщение" className="message__blind_label message__im-mess--blind-select _im_mess_blind_label_select"></span> 
			   <span className="message__blind_label message__im-mess--blind-read _im_mess_blind_unread_marker"></span> 
			   <span className="message__im-mess--marker _im_mess_marker"></span> 
			 </li> 
		  </ul> 
	   </div> 
	</div>
    

  );
});

Message.defaultProps = {
  //USER: {},
};

Message.displayName = 'Message';
Message.whyDidYouRender = true

Message.propTypes = {
  avatar: PropTypes.string,
  MSG_TEXT: PropTypes.string,
  MSG_DATE: PropTypes.number,
  USER: PropTypes.object,
  ATTACHMENTS: PropTypes.array,
  isMe: PropTypes.bool,
  isReaded: PropTypes.bool,
  isTyping: PropTypes.bool,
  audio: PropTypes.string,
};

export default Message;
