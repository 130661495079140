import { withFormik } from 'formik';
import React  from "react";

import LoginForm from '../components/LoginForm';

import validateForm from 'chatSource/utils/validate';

import { useAppState, appState } from "chatSource/redux/reducers/dialogs";
import { dialogsActions } from 'chatSource/redux/actions';

//import store from 'chat/redux/store';

const LoginContainer = (props) => {
	    
		const [state, dispatch] = useAppState();
		const history = props.history;
		const LoginFormContainer = withFormik({
		  enableReinitialize: true,
		  mapPropsToValues: () => ({
			email: 3870,
			password: '',
		  }),
		  validate: values => {
			let errors = {};

		//    validateForm({ isAuth: true, values, errors });

			return errors;
		  },
		  handleSubmit: (values, { setSubmitting, props }) => {
			  console.log('1111');
			//const [state, dispatch] = appState();
		//    store.
//			dispatch(
			// вот в таком варианте все заработало!
			dialogsActions.fetchUserData(values.email,dispatch)
//			)
			  .then(({ status }) => {
			  //  if (status === 'success') {
				  history.push('/chat');
			  //  }
				setSubmitting(false);
			  })
			  .catch(() => {
				setSubmitting(false);
			 });
		  },
		  displayName: 'LoginForm',
		})(LoginForm);
		
		return <LoginFormContainer/>

	
}

export default LoginContainer;
