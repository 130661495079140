import { dialogsApi, userApi } from 'chatSource/utils/api'; //, messagesApi
import { openNotification } from 'chatSource/utils/helpers';
import {  notification } from 'antd-v3'; //Button,

//import { dialogsActions } from 'chat/redux/actions';
// Это экшены работы со списками диалогов
const Actions = {
  setDialogs: items => ({
    type: 'DIALOGS:SET_ITEMS',
    payload: items,
  }),

//  setParticipants: items => ({
    //type: 'DIALOGS:SET_PARTICIPANTS',
    //payload: items,
  //}),
  deleteDialog: ( dialogId ) => ({
    type: 'DIALOGS:DELETE_DIALOG',
    payload: {
      dialogId,
    },
  }),
  isDeleting: ( isDeleting ) => ({
    type: 'DIALOGS:IS_DELETING',
    payload: {
      isDeleting,
    },
  }),
  isLoading: ( isLoading ) => ({
    type: 'DIALOGS:IS_LOADING',
    payload: {
      isLoading,
    },
  }),
  
  updateReadedStatusAndSetLastMsgInfo: (dialogId,msg,changeUnreaded) => (
  {
	  type: 'DIALOGS:UPDATE_READED_AND_SET_LAST_MSG',
	  payload: {
		  dialogId,
		  msg,
		  changeUnreaded
	  }
  }
  ),
// что приходит при создании нового диалога ДОБАВЛЯЕМ в стейт
  addNewDialog: (data) => (
  {
	  type: 'DIALOGS:APPEND_DIALOG',
	  payload: {
		  dialog : data 
	  }
  }
  ),

//////////////////////////////////////////////////////////////////////////////////////////////////
  // это чтоб реально вызвать редьюсер
  updateReadedStatusLocalInt: ( dialogId ) => ({
    type: 'DIALOGS:LAST_MESSAGE_READED_STATUS_LOCAL',
    payload: {
      dialogId,
    },
  }),

  // экшен, который находит в списке диалогов нужный и добавляет туда последнее сообщение
  addLastMessageToDialogsList: (dialogId,msg) => ({
		type: 'DIALOGS:ADD_LAST_MESSAGE_TO_DIALOG_LIST',
		payload: {
		  dialogId,
		  msg
		},
	  
  }),
//////////////////////////////////////////////////////////////////////////////////////////////////  

  // добавляем признак создания диалога
  startCreatingDialog: (docType,docId) => ({
		type: 'DIALOGS:START_CREATING',
		payload: {
		  docType,
		  docId
		},
	  
  }),
  clearCreatingDialog: () => ({
		type: 'DIALOGS:CLEAR_CREATING',
	  
  }),

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  incrementReaderMessagesCounter: (dialogId) => ({
		type: 'DIALOGS:INCREMENT_READED_MESSAGES_COUNTER',
		payload: {
		  dialogId,
		},
	  
  }),
  clearDialogUnreadedOther: (dialogId) => ({
		type: 'DIALOGS:CLEAR_DIALOG_UNREADED_OTHER',
		payload: {
		  dialogId,
		},
	  
  }),
  setUserOnlineState: (userId,onlineState) => ({
		type: 'DIALOGS:SET_ONLINE_STATE',
		payload: {
		  userId,
		  onlineState,
		},
	  
  }),
  updateReadedStatusLocal: ( dialogId,dispatch ) =>  { //dispatch => {
	  // сбрасываем колво непрочитанных в НОЛЬ
	  dispatch(Actions.updateReadedStatusLocalInt(dialogId)); 
  }, //=> dispatch
  setCurrentDialogId: (id, dispatch)  => {
	console.log('!setCurrentDialogId');
	// надо дернуть сообщения с сервера
	if (id !== undefined) {
		//socket.emit('DIALOGS:JOIN', id);	
		// вот это вот не надо скорее всего, тк в messages привязка к изменению ИД диалога. чтоб два раза не срабатывало
//		dispatch(messagesActions.fetchMessages(id));
	}
    dispatch({
      type: 'DIALOGS:SET_CURRENT_DIALOG_ID',
      payload: id,
    });
  }, //dispatch =>

  setCurrentDialogParams: (type,docId, dispatch)  => {
	console.log('!setCurrentDialogParams');
	// надо дернуть сообщения с сервера
    dispatch({
      type: 'DIALOGS:SET_CURRENT_DIALOG_PARAMS',
      payload: {
		  currentChatType: type,
		  currentChatDoc: docId
	  }
    });
  }, //dispatch =>


  fetchDialogs: (uid,dispatch) =>  {
	  console.log('fetchDialogs');
	  console.log(uid);
    dialogsApi.getAll(uid).then(({ data }) => {
      dispatch(Actions.setDialogs(data)); //.DIALOGS
	  //dispatch(Actions.setParticipants(data.participants)); //.DIALOGS
    });
  },
//////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////
  setUserData: data => {
	//localStorage.setItem('chatUser'+'_'+state.data.id,msgs);  
  //alert('setting userData '+JSON.stringify(data));
  return {
    type: 'USER:SET_DATA',
    payload: data,
  }
  
  },
  setIsAuth: bool => ({
    type: 'USER:SET_IS_AUTH',
    payload: bool,
  }), //dispatch =>
  fetchUserData: (uid,dispatch) =>  {
    return userApi
      .getMe(uid)
      .then(({ data }) => {
        dispatch(Actions.setUserData(data));
		return true; // тут так, иначе не срабоатет в loginForm выход из экшена
      })
      .catch(err => {
		if (err.response !== undefined)   
		{
			if (err.response.status === 403) {
			  dispatch(Actions.setIsAuth(false));
			  delete window.localStorage.token;
			}
		} else
		{
			alert('Ошибка получения данных с сервера');
			  notification.error({
					message: 'Внимание',
					description:
						'Ошибка получения данных с сервера',
			  });
		}
      });
  },
  fetchUserData1: uid => dispatch => {
    return userApi // тут именно так, но почему не знаю
      .getMe(uid)
      .then(({ data }) => {
        dispatch(Actions.setUserData(data));
		return true; // тут так, иначе не срабоатет в loginForm выход из экшена
      })
      .catch(err => {
		if (err.response !== undefined)   
		{
			if (err.response.status === 403) {
			  dispatch(Actions.setIsAuth(false));
			  delete window.localStorage.token;
			}
		} else
		{
			alert('Ошибка получения данных с сервера');
			  notification.error({
					message: 'Внимание',
					description:
						'Ошибка получения данных с сервера',
			  });
		}
      });
  },
  fetchUserLogin: postData => dispatch => {
    return userApi
      .signIn(postData)
      .then(({ data }) => {
        const { token } = data;
        openNotification({
          title: 'Отлично!',
          text: 'Авторизация успешна.',
          type: 'success',
        });
        window.axios.defaults.headers.common['token'] = token;
        window.localStorage['token'] = token;
        dispatch(Actions.fetchUserData());
        dispatch(Actions.setIsAuth(true));
        return data;
      })
      .catch(({ response }) => {
        openNotification({
          title: 'Ошибка при авторизации',
          text: 'Неверный логин или пароль',
          type: 'error',
        });
      });
  },
  fetchUserRegister: postData => () => {
    return userApi.signUp(postData);
  },
   
};

export default Actions;
