import React from 'react';
import { Modal, Empty, Spin  } from 'antd-v3';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Message } from '../';

import './Messages.scss';


//2022import { useAppState } from "chatSource/redux/reducers/dialogs";

const Messages = ({
  globalUser,
  blockRef,
  //items, // два массива сообщения и пользователи. пристегнуты через connect в контейнере аналогичном
  isLoading,
  onRemoveMessage,
  //isError,
  setPreviewImage,
  previewImage,
  blockHeight,
  isTyping,
  partner,
  mainDispatcher,
  messagesDispatch,
  isDesktopOrLaptop,
  messagesstate
}) => {
	
	
	
  return (
    <div className="mychat__dialog-messages" style={{ height: `calc(100% - ${blockHeight}px)` }}>
      <div ref={blockRef} className={classNames('messages', { 'messages--loading': isLoading })}>
        {isLoading && !globalUser ? (
          <Spin size="large" tip="Загрузка сообщений..." />
        ) : messagesstate.messages.MESSAGES && !isLoading ? (
          messagesstate.messages.MESSAGES.length > 0 ? (
            messagesstate.messages.MESSAGES.map(item => (
              <Message
                {...item}
				userId = {item.USER.ID}
				users = {messagesstate.messages.USERS}
				isDesktopOrLaptop= {isDesktopOrLaptop}
                isMe={globalUser == item.USER.ID}  // item.user._id
				globalUser={globalUser}
                onRemoveMessage={onRemoveMessage}
                setPreviewImage={setPreviewImage}
                key={item.MSG_ID}
				mainDispatcher={mainDispatcher}
				messagesDispatch={messagesDispatch}
              />
            ))
          ) : (
            <Empty description="Диалог пуст" />
          )
        ) : (
          <Empty description="Откройте диалог1" />
        )}
        {isTyping && <Message isTyping={true} user={partner} />}
        <Modal visible={!!previewImage} onCancel={() => setPreviewImage(null)} footer={null}>
          <img src={previewImage} style={{ width: '100%' }} alt="Preview" />
        </Modal>
      </div>
    </div>
  );
};
Messages.whyDidYouRender = true

Messages.propTypes = {
  items: PropTypes.object, //!!!
};

export default Messages;
