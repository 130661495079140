import React, {memo} from 'react';
import classNames from 'classnames';
import format from 'date-fns/format';
import isToday from 'date-fns/is_today';
import { Link } from 'react-router-dom';
import mainRoute from 'chatSource/routes'; 
//import closeImg from "chat/assets/img/x.svg";

//import store from 'chat/redux/store';
import { dialogsActions,messagesActions } from 'chatSource/redux/actions';

import { useAppState } from "chatSource/redux/reducers/dialogs";

import { IconReaded, Avatar, DialogCloseButton } from '../';

import { dialogsApi } from 'chatSource/utils/api';

const getMessageTime = createdAt => {
  if (isToday(createdAt)) {
    return format(createdAt, 'hh:mm');
  } else {
    return format(createdAt, 'dd.MM.yyyy');
  }
};
//	  <DialogCloseButton did = {CHAT_ID}  />
const renderLastMessage = (LAST_MSG_TEXT, LAST_MSG_USER_ID, userId) => {
  let text = '';
  //&& message.attachments.length
  if (!LAST_MSG_TEXT ) {
    text = 'прикрепленный файл';
  } else {
    text = LAST_MSG_TEXT;
  }

  return `${LAST_MSG_USER_ID === userId ? 'Вы: ' : ''}${text}`;
};

const DialogItemContainer = memo(({
  CHAT_ID,
  UNREADED,
  UNREADED_OTHER,
  undread,
  CHAT_DATE,
  text,
  isMe,
  key,
//  lastMessage,
  LAST_MESSAGE,
  userId,
  CHAT_TYPE_EXT_TEXT,
LAST_MSG_USER_ID,
LAST_MSG_TEXT,
LAST_MSG_SEEN,
STARTER_ID,
LAST_MESSAGE_AVATAR,
isDeleting,
dispatch,
messagesDispatch,
setmodalVisible,
isDesktopOrLaptop,
participants,
isCurrentDialog,
CHAT_TYPE,
CHAT_DOC_ID

  
}) =>  {
	var starter = participants.filter(item=> item.ID == STARTER_ID)[0];
	var lastMsg = participants.filter(item=> item.ID == LAST_MSG_USER_ID)[0];
	
     //const [state,dispatch] = useAppState();	
	 // все равно тут это все на каждое изменение ренендерится
	 return (
	    <DialogItem 
		key={key}
  CHAT_ID={CHAT_ID}
  UNREADED={UNREADED}
  UNREADED_OTHER={UNREADED_OTHER}
  undread={undread}
  CHAT_DATE={CHAT_DATE}
  text={text}
  isMe={isMe}
  CHAT_TYPE={CHAT_TYPE}
  CHAT_DOC_ID={CHAT_DOC_ID}
  STARTER_ONLINE={starter.ONLINE}
  LAST_MSG_USER_ID={LAST_MSG_USER_ID}
LAST_MSG_TEXT={LAST_MSG_TEXT}
LAST_MSG_SEEN={LAST_MSG_SEEN}
STARTER_ID={starter.ID}
STARTER_AVATAR={starter.AVATAR}
STARTER_FULLNAME={starter.FULLNAME}
LAST_MESSAGE_AVATAR={lastMsg.AVATAR}
isDesktopOrLaptop = {isDesktopOrLaptop}



  //lastMessage={lastMessage}
  ////LAST_MESSAGE={LAST_MESSAGE}
  userId={userId}
  CHAT_TYPE_EXT_TEXT={CHAT_TYPE_EXT_TEXT}
  dispatch={dispatch}
  messagesDispatch={messagesDispatch}
  isDeleting={isDeleting}
  setmodalVisible={setmodalVisible}
  isCurrentDialog={isCurrentDialog}
		/>
	 )
});
DialogItemContainer.displayName="DialogItemContainer";

const DialogItem = memo(
({
  CHAT_ID,
  UNREADED,
  UNREADED_OTHER,
  //undread,
  CHAT_DATE,
  text,
  isMe,
  STARTER_ONLINE,//STARTER_ONLINE,
  //lastMessage,
////2021  LAST_MESSAGE,
  userId,
  CHAT_TYPE_EXT_TEXT,
  dispatch,
  messagesDispatch,
  isDeleting,
LAST_MSG_USER_ID,
LAST_MSG_TEXT,
LAST_MSG_SEEN,
STARTER_ID,
STARTER_AVATAR,
STARTER_FULLNAME,
LAST_MESSAGE_AVATAR,
setmodalVisible,
isDesktopOrLaptop,
isCurrentDialog,
CHAT_TYPE,
CHAT_DOC_ID


  //UNREADED_OTHER - непрочитанные в текущем диалоге с той стороны. те пользователь посылал, а другой еше не прочитал
  //UNREADED - непрочитанные у текущегог пользователя. те то, что он сам не прочитал
  // те по логике может быть либо unreaded - это у меня не прочитано. либо other - мое не прочитано
  //
  //

  
}) =>  {
	const renderText = () => 
	{
		if (UNREADED_OTHER > 0) { // если непрочитаны с той стороны - рендерим покрашеное
			return <span className="dialogs__nim-dialog--inner-text dialogs__nim-dialog--inner-text_unreaded_other">{LAST_MSG_TEXT}</span>
		} else 
		{
			return <span className="dialogs__nim-dialog--inner-text">{LAST_MSG_TEXT}</span>			
		}
		
	}
console.log('renderind dialogitem '+CHAT_ID);
console.log(isCurrentDialog);
//	console.log(CHAT_ID);

//!!!!!!!!!!!! тут просто так маленького аватара не добавишь - все разъезжается
return (
  <Link to={mainRoute+`/dialog/${CHAT_TYPE}/${CHAT_DOC_ID}`} onClick={()=> {
// вот это вот запускается один раз при рендере
	  console.log('onclickonclickonclick'+CHAT_TYPE);
	  // сбрасываем ошибку
	  messagesDispatch(messagesActions.setIsMessagesError(false));// сбросим ошибку. удачно, но наверно концептуально плохо

	  // тут помимо установки ИД диалога мы сбрасываем messages
	  console.log(isDeleting);
	  if (!isDeleting) {
			  //dialogsActions.setCurrentDialogId(CHAT_ID,dispatch) ;
			  dialogsActions.setCurrentDialogParams(CHAT_TYPE,CHAT_DOC_ID,dispatch); 
			  
			  //dispatch(dialogsActions.isDeleting(true)) 
	  } else 
	  {      // сюда приходим когда признак удаления true. просто сбрасываем его
             dispatch(dialogsActions.isDeleting(false)) 		  
	  }
//	*/  
	  }}>
	  
	  <li  className= {(!isCurrentDialog) ? 'dialogs__nim-dialog' : 'dialogs__nim-dialog dialogs__nim-dialog_current' } > 

	<div className="dialogs__nim-dialog--photo">
       <Avatar  ID={STARTER_ID}  AVATAR={STARTER_AVATAR} FULLNAME={STARTER_FULLNAME } isSmall={false} />
	</div>  
			   <div className="dialogs__nim-dialog--content">
				  <div className="dialogs__nim-dialog--cw">
					 <span role="link" className="dialogs__blind_label" aria-label="Перейти к беседе: Ольга Новинская"></span> 
					 <div className="dialogs__nim-dialog--date_wrapper">
						<button type="button" className="dialogs__nim-dialog--star _im_dialog_star"></button> 
						<div className="dialogs__nim-dialog--date _im_dialog_date">{getMessageTime(CHAT_DATE)} </div>
					 </div>
					 <button type="button" className="dialogs__nim-dialog--close _im_dialog_close" data-peer="22626031"  onClick={(e)=> {
						 // именно так все отрабатывает. на ссылке не кликается
						 e.stopPropagation();
						e.preventDefault(); 
						dialogsApi.delete(CHAT_ID).then ( value=> {
						 console.log(value);
						 if (value.data.success == false) {
							 alert(value.data.msg);
							 
						 }
						 ///alert('Пока не реализовано!') 
						 
					 }
				  
				              
				  );
						return;
						setmodalVisible(true);	
//            dispatch(dialogsActions.isDeleting(true)) 		  
		    // сначала поставим ИД диалога пустым иначе статус будет неверно отображаться
			
//		     dispatch(dialogsActions.deleteDialog(CHAT_ID));
						 }}  
						 onMouseOver={(e)=>{console.log('f')}}
						 
						 ></button> 
					 <button type="button" className="dialogs__nim-dialog--markre _im_dialog_markre"></button> 
					 <div className="dialogs__nim-dialog--name">
						<span className="dialogs__nim-dialog--name-w _im_dialog_name_w" aria-hidden="true"> 
						<span className="_im_dialog_link">{CHAT_TYPE_EXT_TEXT}
						<span className="dialogs__image_status__status image_status__status--normal">
						{/**						<img className="dialogs__dialogs__image_status__statusImage" src="https://sun9-61.userapi.com/nEr_95m4LJXlXeP76-8cT46wyAfwDxTQiFYD8A/CtV84IxhLNQ.png" srcSet="https://sun9-2.userapi.com/bVMQkJpC6yzj8ugGzKq-k8Cy8kDy90YRW8SN_A/m0cJ2BElRbQ.png 2x"/> */}
						</span>
						</span> 
						</span> 
						<span className="dialogs__nim-dialog--verfifed _im_dialog_verified"></span> 
						<div className="dialogs__nim-dialog--peer-tags _im_dialog_peer_tags PeerTags"></div>
					 </div>
					 <div className={isDesktopOrLaptop ? "dialogs__nim-dialog--text-preview nim-dialog--text-preview_wrap_text" : "nim-dialog--text-preview_wrap_text"}>
						<span className="dialogs__nim-dialog--preview _dialog_body">
						   <span className="dialogs__nim-dialog--who">
							  <div className="dialogs__im-prebody"> 
								 <img alt="" src={LAST_MESSAGE_AVATAR}/> 
							  </div>
						   </span>
						   {/**/}

							   {renderText()}
						   
						</span>
						<span className="dialogs__nim-dialog--typing _im_dialog_typing"></span>
						<span className="dialogs__nim-dialog--typer-el"></span> 
					 </div>
					 <div className="dialogs__nim-dialog--pinned-conversation" role="img"></div>
					 <div className="dialogs__nim-dialog--unread_container">
						<div className="dialogs__nim-dialog--unread-badge_mention dialogs__nim-dialog--unread-badge" aria-label="Непрочитанные упоминания" role="img"></div>
						<label className="dialogs__blind_label _im_unread_blind_label"></label> 
						{UNREADED >0 && (
						     <div className="dialogs__nim-dialog--unread dialogs__nim-dialog--unread--visible _im_dialog_unread_ct" aria-hidden="true">{UNREADED}</div>
						)}
					 </div>
					 {/*тут блок, который будет показывается над кнопкой удаления*/}
                     <div className="dialogs__tt_w dialogs__tt_black dialogs__tocenter dialogs__tt_down" 
					 style={{position: 'absolute', opacity: '1', top: '37px', left: '262.531px', pointerEvents: 'auto', display: 'none'}}
					 >
					 <div className="dialogs__tt_text">Удалить</div>
					 </div>					 
				  </div>
			   </div>




	  

    </li>
  </Link>
)
})
;
DialogItem.whyDidYouRender = true
DialogItem.displayName='dialogItemMemorized'
DialogItemContainer.whyDidYouRender = true
export default memo(DialogItemContainer);

