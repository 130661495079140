
import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';

import { generateAvatarFromHash } from "chatSource/utils/helpers";

import "./Avatar.scss";

const Avatar = ({ ID, AVATAR, FULLNAME, isSmall }) => {
var z = 		  classNames("dialogs__nim-peer", {
			'dialogs__nim-peer_small': isSmall,
			'dialogs__nim-peer_big': !isSmall, 
			'dialogs__fl_l': isSmall			
			  
		  })
;
var z1 =		  classNames({
			'dialogs__im_grid': !isSmall, 
			'dialogs__im_grid_small': isSmall,
			  
		  })
;
  //if (!FULLNAME) FULLNAME= 'ddddd';
  //if (isSmall) AVATAR = undefined;

  //AVATAR = process.env.PUBLIC_URL+'/img/man-svgrepo-com.svg';
  if (AVATAR) {
    return (
    <div className={z1}>	
      <img
        className="chatavatar"
        src={AVATAR}
        alt={`Avatar ${FULLNAME}`} style ={ {height: 40, width: 40}}
      />
   </div>	  
    );
  } else {
//    const { color, colorLighten } = generateAvatarFromHash(ID);
//    const firstChar = FULLNAME[0].toUpperCase();
    return (
      <div 
	  className = {z}

	  >
         <div className="dialogs__nim-peer--photo-w">
            <div className="dialogs__nim-peer--photo _im_dialog_photo">
               <div className="_im_peer_target _online_reader">
                  <div className={z1}>
                     <img src="https://sun9-27.userapi.com/s/v1/ig2/52Go_-HqBansvfCZ-cHFROTz8iZbrCZn7SR7TxtGkV39eLYYS1CiJshu2aJrOuYi3UOoGW17Z-LD0waAuZBRihbE.jpg?size=50x0&quality=96&crop=0,161,1089,1089&ava=1" alt=""/>
                  </div>
               </div>
            </div>
         </div>

      </div>
    );
  }
};

Avatar.propTypes = {
  className: PropTypes.string
};

export default Avatar;
