import React, { useState, useEffect,useCallback } from 'react';//,memo
//import { connect } from 'react-redux';
import format from 'date-fns/format';
import intersection from "lodash/intersection";
// импортируются экшены диалога
import { dialogsActions } from 'chatSource/redux/actions';
//import socket from 'chat/core/socket';

import { useAppState } from "chatSource/redux/reducers/dialogs";
import { useAppMessagesState } from "chatSource/redux/reducers/messages";


import { Dialogs as BaseDialogs } from 'chatSource/components';

// эта компонента основная, просто используется тут внутри из папки components как базовый
// вот эти вот два первых это ссылки на actions функции
//currentChatType,currentChatDoc это из пропсов взято в store
//items это из пропсов взято в store
//userId это из пропсов. точно из пропсов. явно задано в sidebar
//currentChatType,currentChatDoc, items,  , user

const DialogsContainer = () => {
  const [inputValue, setValue] = useState('');
  const [state, dispatch] = useAppState();  // {1}
  const [messagesState, messagesDispatch] = useAppMessagesState();  // {1}

	var currentChatType = state.currentChatType;
	var currentChatDoc = state.currentChatDoc;
	
	
	var items = state.messages;
	var isDeleting = state.isDeleting;
  const [filtred, setFiltredItems] = useState(Array.from(items)); // подключается из store ниже	
  //const value1 = items;
  console.log('filtered');
  console.log(filtred);
  
  console.log('start dialogs ');
  
  const onChangeInput = 
  useCallback(
  (value = '') => {   //''
  console.log('!!!on search!!!!');
  console.log(value);
  console.log(items);
    var z = value.split(' '); // получили массив слов из строки
	
	var q =  z.map((item) => {

	const matchedObject = items.filter( 
			(option,o2,o3) => (
				  (option.CHAT_TYPE_EXT_TEXT.toLowerCase().indexOf(item.toLowerCase()) >= 0 ||
				  format(option.CHAT_DATE, 'dd.MM.yyyy').indexOf(item.toLowerCase()) >= 0)
			)
		);
		console.log(matchedObject);
		return matchedObject;
		
	});
	
	// пересечение двух массивов. вернее не двух, а стольки, сколько их есть
	z = intersection(...q);
	
    setValue(value); //value
	setFiltredItems(z); 
	console.log(filtred);
  },[items]); // вот это дает возможность изменять колбэк при изменении items. но и перерисовка идет. но если убрать, то не будет видеть items

//  window.fetchDialogs = fetchDialogs;

  
//  const fetchDialogsInt = (uid) => { 
	  //fetchDialogs(userId);
//  };
// странно, но без этого не происходит фетч диалогов  
  useEffect(() => {
    if ((items.length) ) { 
      onChangeInput();
    }
  }, [items]);

  useEffect(() => {
	  console.log('tracked effect');
	  console.log(state.data.id);
	  console.log(typeof state.data.id);
    dialogsActions.fetchDialogs(state.data.id,dispatch);

  }, [state.data.id,dispatch]); //
  
  return <BaseDialogs
      userId={state.data.id}
      items={filtred}
	  participants = {state.participants}
      onSearch={onChangeInput}
      inputValue={inputValue}
	  isDeleting={isDeleting}
	  currentChatType={currentChatType}
	  currentChatDoc={currentChatDoc}
	  dispatch={dispatch}
	  messagesDispatch={messagesDispatch}
    />
    
    
	
};

//
DialogsContainer.whyDidYouRender = true
export default DialogsContainer;
