import React from "react";
import { Status as StatusBase } from "chatSource/components";
//import { connect } from "react-redux";

import { useAppState } from "chatSource/redux/reducers/dialogs";
 
 
const StatusContainer = ({ dialogs }) => {
	
   const [state, AppDispatch] = useAppState();
	
	var currentChatType = state.currentChatType;
	var currentChatDoc = state.currentChatDoc;
	
  if (!state.messages.length || !state.currentChatDoc) {
    return null;
  }

  const currentDialogObj = state.messages.filter(
   // eslint-disable-next-line
    dialog => dialog.CHAT_DOC_ID == currentChatDoc  && dialog.CHAT_TYPE == currentChatType
  )[0];
 
  let partner = {};
  //alert(' my - '+state.data.id);
//  alert(' starter - '+currentDialogObj.STARTER.ID);
  if (currentDialogObj) {
  if (currentDialogObj.STARTER == state.data.id) { // не строгое сравнение
  // получаем партнера из списка партнеров
    partner = state.participants.filter(item => item.ID == currentDialogObj.PARTNER)[0];
  } else {
    partner = state.participants.filter(item => item.ID == currentDialogObj.STARTER)[0]
  }

//  alert(' starter - '+currentDialogObj.STARTER.ID+' ' +currentDialogObj.STARTER.ONLINE);
//  alert(' partner - '+currentDialogObj.PARTNER.ID+' ' +currentDialogObj.PARTNER.ONLINE);
//  alert(' partnerz - '+Boolean(partner.ONLINE));
  // если статус = 2, тогда это типа никогда не был
  // без последнего параметра memo запоминает компонент и не рендерит
  return <StatusBase online={partner.ONLINE} fullname={partner.FULLNAME} AppDispatch = {AppDispatch} CHAT_TEXT = {currentDialogObj.CHAT_TYPE_EXT_TEXT}  CHAT_ID = {currentDialogObj.CHAT_ID} />;
  } else return null;
};

StatusContainer.whyDidYouRender = true
//dialogs,  user
export default 
//connect(({  }) => ({
  //dialogs: dialogs.items,
  
  //user: user.data
//}))(
StatusContainer
//)
;
