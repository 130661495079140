import React from "react";
import { Route } from "react-router-dom";

import { LoginForm, RegisterForm } from "chatSource/modules";
import CheckEmailInfo from "./components/CheckEmailInfo";
import mainRoute from 'chatSource/routes'; 

import "./Auth.scss";

const Auth = () => {
return(
  <section className="auth">
    <div className="auth__content">
      <Route exact path={mainRoute+"/signin"} component={LoginForm} />
      <Route exact path={mainRoute+"/signup"} component={RegisterForm} />
      <Route exact path={mainRoute+"/signup/verify"} component={CheckEmailInfo} />
    </div>
  </section>
  )
};

export default Auth;
