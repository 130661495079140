import React, {memo, useState} from "react";
import orderBy from "lodash/orderBy";
import { Input, Empty, Modal } from "antd-v3";
import { DialogItem as DialogItemContainer } from "../";
//CHAT_DATE 
import "./Dialogs.scss";
//тут у нас отрисовка
// все передается исключительно через пропсы в контейнере
// мемоизировали и убрался ререндер
const Dialogs = memo(({ items, userId, onSearch, inputValue, isDeleting,dispatch,messagesDispatch,isDesktopOrLaptop,participants,currentChatType, currentChatDoc
 }) => 
	{
		  const [modalVisible, setmodalVisible] = useState(false);
			console.log(currentChatDoc);

 const getAvatar = (uid) => {
		var z = participants.filter(itemm => itemm.ID == uid);
		if (z.length !=0) {
			return z[0].AVATAR;
			
		} else {
			return '';
		}
  };

return(

<div  style={{height : "100%"}}>
 <div className="dialogs__search">
      <Input.Search
	    allowClear
        placeholder="Поиск по списку"
        onChange={e => onSearch(e.target.value)}
        value={inputValue}
      />
    </div>	  
	<div className="dialogs">
 
   
    {items.length ? (
      orderBy(items, ["UNREADED","LAST_MESSAGE.LAST_MSG_DATE"], ["desc","desc"]).map(item => (
        <DialogItemContainer
          key={item.CHAT_ID}
		  isDesktopOrLaptop={isDesktopOrLaptop}
		  dispatch={dispatch}
		  messagesDispatch={messagesDispatch}
          //isMe={item.STARTER_ID === userId}
          userId={userId}
		  CHAT_TYPE={item.CHAT_TYPE}
		  CHAT_DOC_ID={item.CHAT_DOC_ID}
          CHAT_ID={item.CHAT_ID}
//		  CHAT_TYPE={item.CHAT_TYPE}
//		  CHAT_DOC_ID={item.CHAT_DOC_ID}
		  UNREADED={item.UNREADED}
		  UNREADED_OTHER={item.UNREADED_OTHER}
		  CHAT_DATE={item.CHAT_DATE}
		  CHAT_TYPE_EXT_TEXT={item.CHAT_TYPE_EXT_TEXT}
		  STARTER_ID = {item.STARTER}
		  
		  LAST_MSG_USER_ID={item.LAST_MESSAGE.LAST_MSG_USER_ID}
		  LAST_MSG_TEXT={item.LAST_MESSAGE.LAST_MSG_TEXT}
          LAST_MSG_SEEN={item.LAST_MESSAGE.LAST_MSG_SEEN}
		  LAST_MESSAGE_AVATAR={getAvatar(item.LAST_MESSAGE.LAST_MSG_USER_ID)}
		  isDeleting={isDeleting}
		  setmodalVisible={setmodalVisible}
		  participants = {participants}
		  isCurrentDialog = {item.CHAT_TYPE == currentChatType && item.CHAT_DOC_ID == currentChatDoc}
        />
      ))
    ) : (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="Ничего не найдено"
      />
    )}
  </div>
        <Modal visible={!!modalVisible}  onCancel={() => setmodalVisible(false)}   footer={null}>

        </Modal>
  
</div>
)}
	);
Dialogs.whyDidYouRender = true
Dialogs.displayName = 'Dialogs'
export default Dialogs;
