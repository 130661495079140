import React, {memo} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {useMediaQuery  } from 'react-responsive'
import { Link } from 'react-router-dom';
//import store from 'chat/redux/store';
import { dialogsActions } from 'chatSource/redux/actions';
import format from 'date-fns/format';

//device={{ deviceWidth: 1600 }}
import { Button, Popover } from 'antd-v3';
//2021 может тут лищнее. на него при компиляции ругается 
//import 'antd-v3/dist/antd-v3.css'; // new line of code
import { LeftOutlined } from '@ant-design/icons';

import { useAppState } from "chatSource/redux/reducers/dialogs";

import './Status.scss';
{/* styles/layouts/_chat  */}
const Status = ({ online, fullname, AppDispatch, CHAT_TEXT, CHAT_ID }) => 
	{
		
//const isDesktopOrLaptop = useMediaQuery({
//    query: '(min-device-width: 1224px)'
//  })
  //const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  //const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  //const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  //const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

//const [dispatch] = useAppState(); вот это убрали. dispatch из другого компонента
// если статус = 2, тогда это типа никогда не был
	var onlineState;
	// сохраняем статус онлайн в другой переменной
	if (online >=0 && online <=1) 
	{
		onlineState = online;
	}
	
		
		
		
	onlineState = 0;
	if (online ==1) onlineState = 1;  // НЕ СТРОГОЕ СООТВЕТСТВИЕ
	//alert('onlineState '+onlineState);
//	alert('onilne '+online);
	


return (
  <div className="mychat__dialog-header">
  {/* вроде работает показывает только на мобильных */}
	{isTabletOrMobileDevice &&
      <div className="mychat__dialog-header-actionleft">
        <Link to={`/chat`} >	  
        <Button onClick = {(props) => {
			   // Может криво, но работает
			   // тут помимо установки ИД диалога мы сбрасываем messages
//			   alert('123123123');
//			   dialogsActions.setCurrentDialogId(undefined,AppDispatch)
			   }} >
			<LeftOutlined />		
			Назад

		</Button>
		</Link>
      </div>
	}

    
	
	<div className="mychat__dialog-header-center">
      <b className="mychat__dialog-header-username">{CHAT_TEXT}</b>	
      <b className="mychat__dialog-header-username">{fullname}</b>
  
      <div className="mychat__dialog-header-status">
        <span className={classNames('status', { 'status--online': onlineState })}>
          {onlineState ? 'онлайн' : 'был '+format(parseInt(online), 'dd.MM.yyyy HH:mm:ss')}
        </span>
      </div>
    </div>
	{/**   <Popover
      className="chat__dialog-header-actionright"
      content={
        <div>
          <Button>Удалить диалог</Button>
        </div>
      }
      trigger="click">
      <div>
        <Button type="link" shape="circle" icon="ellipsis" />
      </div>
    </Popover> */}
  </div>
	)};

Status.whyDidYouRender = true
Status.propTypes = {
  //online: PropTypes.bool,
};

export default memo(Status);
