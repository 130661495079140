import React, { Profiler, memo} from 'react';
import { Icon, Button, Modal, Select, Input, Form } from 'antd-v3';
import { Dialogs } from 'chatSource/containers';
import { X, Search, CheckSquare, Bell, User, Trash } from 'react-feather'
import classnames from 'classnames'
import {useMediaQuery  } from 'react-responsive'


import './Sidebar.scss';

import {
  Card,
  CardText,
  InputGroup,
  InputGroupAddon,
 
  InputGroupText,
  Badge,
  CustomInput,
 
} from 'reactstrap'

const { Option } = Select;
const { TextArea } = Input;
//	    <Profiler id="Navigation" onRender={onRenderCallback}>
//        <Dialogs userId={user && user.id} />
//		</Profiler>

// добавляем мемо чтоб не перерисовывался лишний раз
// вроде перестал rerenderиться
const Sidebar = memo(
({
  user,
  modalVisible,
  inputValue,
  messageText,
  selectedUserId,
  isLoading,
  users,
  onShow,
  onClose,
  onSearch,
  sidebarVisible,
  onChangeInput,
  onSelectUser,
  onContactSearch,
  onChangeTextArea,
  onModalOk,
  handleUserSidebarLeft
}) => {

	const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })  


  const options = users.map(user => <Option key={user._id}>{user.fullname}</Option>);


  let chatVisibleStyle;

  // ширину убрал из styles/layout/_chat.css
  if (isDesktopOrLaptop) {
         chatVisibleStyle = { width: '420px', display: sidebarVisible ? null : 'none'};
  } else 
  {
         //chatVisibleStyle = { flex: 1, display: sidebarVisible ? null : 'none'};	  
		 chatVisibleStyle = { width: '100%', display: sidebarVisible ? null : 'none'};	  
  }
  /*if (sidebarVisible) {
  chatVisibleStyle  = {
      display: 'block'
    };
  } else {
	  
  chatVisibleStyle  = {
      display: 'display: none;'
    };
	  
  }*/
  // вроде ок
  //var z = classnames('chat__sidebar', {display: sidebarVisible ? 'none' : 'block'});
  var ddd

function onRenderCallback(
  id, // проп "id" из дерева компонента Profiler, для которого было зафиксировано изменение
  phase, // либо "mount" (если дерево было смонтировано), либо "update" (если дерево было повторно отрендерено)
  actualDuration, // время, затраченное на рендер зафиксированного обновления
  baseDuration, // предполагаемое время рендера всего поддерева без кеширования
  startTime, // когда React начал рендерить это обновление
  commitTime, // когда React зафиксировал это обновление
  interactions // Множество «взаимодействий» для данного обновления 
) {
  // Обработка или логирование результатов...
  console.log('!');
//  alert(phase+' '+id);
}

console.log('sidebarrerender');
// условие ниже дало возможность не отрисовывать dialogs если пользователь не указан!!!!
  return (
//style="display: none;"
// монтируется
	    <Profiler id="sidebar" onRender={onRenderCallback}>
    <div className='mychat__sidebar' style={chatVisibleStyle}  >
      <div className="mychat__sidebar-header">
	  {/**        <div style={{width: '220px'}}>  {/**  TODO странный размер от балды*/}
      {/**    <Icon type="team" />
          <span>Список диалогов</span>
        </div>
        <Button onClick={onShow} type="link" shape="circle" icon="form" />  {/**  чуть чуть плавает при изменении размеров, но потом разберемся TODO  */}
		{/**<div onClick={handleUserSidebarLeft} className="sidebar-close-icon"  >   
			     <Button onClick={handleUserSidebarLeft} type="link" shape="circle" icon="close"  />
              
		</div> **/}
		
      </div>
 
      <div className="mychat__sidebar-dialogs">
	   {user && ( 

	    <Profiler id="dialogss" onRender={onRenderCallback}>
        <Dialogs userId={user && user.id} isDesktopOrLaptop={isDesktopOrLaptop} />
		</Profiler>



	   )}
      </div>

      <Modal
        title="Создать диалог"
        visible={modalVisible}
        onCancel={onClose}
        footer={[
          <Button key="back" onClick={onClose}>
            Закрыть
          </Button>,
          <Button
            disabled={!messageText}
            key="submit"
            type="primary"
            loading={isLoading}
            onClick={onModalOk}>
            Создать
          </Button>,
        ]}>
        <Form className="add-dialog-form">
          <Form.Item label="Введите имя пользователя или E-Mail">
            <Select
              value={inputValue}
              onSearch={onSearch}
              onChange={onChangeInput}
              onSelect={onSelectUser}
              notFoundContent={null}
              style={{ width: '100%' }}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              placeholder="Введите имя пользователя или почту"
              showSearch>
              {options}
            </Select>
          </Form.Item>
          {selectedUserId && (
            <Form.Item label="Введите текст сообщения">
              <TextArea
                autosize={{ minRows: 3, maxRows: 10 }}
                onChange={onChangeTextArea}
                value={messageText}
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </div>
		</Profiler>	  	
  );
}
);
Sidebar.whyDidYouRender = true
Sidebar.displayName = 'Sidebar';
Sidebar.defaultProps = {
  users: [],
};

export default Sidebar;
