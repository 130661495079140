// вот это вот задает начальное значение которое подключается потом через connect
import { useReducer, useContext, createContext }  from "react";

const initialState = {
  messages: [],
  participants: [],
  currentDialogId: undefined,
  currentChatType: undefined,
  currentChatDoc: undefined,
  isLoading: false,
  isDeleting: false,
  // Это для того, чтобы хранить признак того, что это создается новый диалог
  isCreating: false,
  //creatingType: 0,
  //creatingId: 0,
  
  // это про пользователя
  data: null,
  token: window.localStorage.token,
  isAuth: false,//!!window.localStorage.token
	// вот это вот можно вынести в отдельный редь.сер
  
};


/*function markMsgReaded(items) {
	function zzz(m) {
		
		return m.map(
			function (item) 	{
                item.MSG_SEEN = 1
				return item

			}
		)
		
		
	}
	items.MESSAGES = zzz(items.MESSAGES);
	
		return items
	
}*/

//= initialState
const reducer = (state , action) => {
	console.log('reducer');
	console.log(action);
	//alert('reducer');
	//alert(JSON.stringify(action));
	//if (action.type === 'DIALOGS:UPDATE_READED_AND_SET_LAST_MSG') {
	//	alert(JSON.stringify(action));
		
//	}
  switch (action.type) {
	  //////////////////////////////////////////////
    case 'DIALOGS:SET_ITEMS':
      return {
        ...state,
        messages: [...action.payload.dialogs],
		participants: [...action.payload.participants],
      };


    //case 'DIALOGS:SET_PARTICIPANTS':
    //  return {
//        ...state,
//        participants: [...action.payload],
//      };
	  //////////////////////////////////////////////	
//    case 'DIALOGS:LAST_MESSAGE_READED_STATUS':
 //     return {
 //       ...state,
  //      items: state.items.map(dialog => {
   //       if (dialog._id === action.payload.dialogId) {
   //         dialog.lastMessage.readed = true;
   //       }
   //       return dialog;
   //     }),
   //   }; 
	  
	 case 'DIALOGS:DELETE_DIALOG':
      return {
        ...state,
        messages: state.messages.filter(
		dialog => dialog.CHAT_ID !== action.payload.dialogId),
      };

	 case 'DIALOGS:IS_LOADING':
      return {
        ...state,
        isLoading: action.payload.isLoading,

      };
	 case 'DIALOGS:IS_DELETING':
      return {
        ...state,
        isDeleting: action.payload.isDeleting,

      };



	 case 'DIALOGS:INCREMENT_READED_MESSAGES_COUNTER':
	   return {
        ...state,
        messages: state.messages.map(dialog => {
          if (dialog.CHAT_ID == action.payload.dialogId) { // тут не строгое
            dialog.UNREADED = dialog.UNREADED+1; // TODO тут скорее всего неправильно концептуально
          }
          return dialog;
        }),


	   };
	 case 'DIALOGS:CLEAR_DIALOG_UNREADED_OTHER':
	   return {
        ...state,
        messages: state.messages.map(dialog => {
          if (dialog.CHAT_ID === action.payload.dialogId) {
            dialog.UNREADED_OTHER = 0;
          }
          return dialog;
        }),


	   };

	 case 'DIALOGS:SET_ONLINE_STATE':
	   console.log('DIALOGS:SET_ONLINE_STATE');
	   //alert('DIALOGS:SET_ONLINE_STATE');
	   //alert(JSON.stringify(action.payload));
	   return {
        ...state,
        participants: state.participants.map(dialog => {
          if (dialog.ID == action.payload.userId) {
            dialog.ONLINE = action.payload.onlineState;
          }
		  return dialog;
        }),


	   };

	   
	   // добавялем новый диалог в стейт
	   case 'DIALOGS:APPEND_DIALOG': 
	   return {
		   ...state,
		   messages: [...state.messages, action.payload.dialog]
	   };

	  // эта часть метит в диалоге все сообщения как ПРОЧИТАННЫЕ
////////////////////////////////////////////////////////////////////////////
    case 'DIALOGS:UPDATE_READED_AND_SET_LAST_MSG':
      return {
        ...state,
        messages: state.messages.map(dialog => {
          if (action.payload.changeUnreaded) {
			  if (dialog.CHAT_ID == action.payload.dialogId) {
                   dialog.UNREADED = 0;
			  } 
          }
		  if (action.payload.msg) {
			  if (dialog.CHAT_ID == action.payload.dialogId) {
				dialog.LAST_MESSAGE.LAST_MSG_ID = action.payload.msg.MSG_ID;
				dialog.LAST_MESSAGE.LAST_MSG_USER_ID = action.payload.msg.USER.ID;
				dialog.LAST_MESSAGE.LAST_MSG_DATE = action.payload.msg.MSG_DATE;
				dialog.LAST_MESSAGE.LAST_MSG_SEEN = action.payload.msg.MSG_SEEN;
				dialog.LAST_MESSAGE.LAST_MSG_TEXT = action.payload.msg.MSG_TEXT;
			  }
		  }
          return dialog;
        }),
      };
    case 'DIALOGS:LAST_MESSAGE_READED_STATUS_LOCAL':
      return {
        ...state,
        messages: state.messages.map(dialog => {
          if (dialog.CHAT_ID === action.payload.dialogId) {
            dialog.UNREADED = 0;
          }
          return dialog;
        }),
      };

    case 'DIALOGS:ADD_LAST_MESSAGE_TO_DIALOG_LIST':
      return {
        ...state,
        messages: state.messages.map(dialog => {
          if (dialog.CHAT_ID == action.payload.dialogId) {
            dialog.LAST_MESSAGE.LAST_MSG_ID = action.payload.msg.MSG_ID;
			dialog.LAST_MESSAGE.LAST_MSG_USER_ID = action.payload.msg.USER.ID;
			dialog.LAST_MESSAGE.LAST_MSG_DATE = action.payload.msg.MSG_DATE;
			dialog.LAST_MESSAGE.LAST_MSG_SEEN = action.payload.msg.MSG_SEEN;
			dialog.LAST_MESSAGE.LAST_MSG_TEXT = action.payload.msg.MSG_TEXT;
          }
          return dialog;
        }),
      };

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case 'DIALOGS:START_CREATING':
      return {
        ...state,
		isCreating: true,
		creatingType: action.payload.docType,
		creatingId: action.payload.docId
		
      };
    case 'DIALOGS:CLEAR_CREATING':
      return {
        ...state,
		isCreating: false
		
      };


////////////////////////////////////////////////////////////////////////////
    case 'DIALOGS:SET_CURRENT_DIALOG_ID':
//	  if (state.currentDialogId != action.payload) 
      return {
        ...state,
//		items: [],
        currentDialogId: action.payload, //parseInt(payload) ? parseInt(payload): 0, //TODO работает, но сомнительно чуток
       };
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case 'DIALOGS:SET_CURRENT_DIALOG_PARAMS':
//	  if (state.currentDialogId != action.payload) 
      return {
        ...state,
        currentChatType: action.payload.currentChatType, 
		currentChatDoc: action.payload.currentChatDoc, 
       };
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	  
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case "USER:SET_DATA":
      return {
        ...state,
        data: action.payload,
        isAuth: true,
        token: window.localStorage.token
      };
    case "USER:SET_IS_AUTH":
      return {
        ...state,
        isAuth: action.payload
      };
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    default:
      return state;
  }
};



const useAppStateContext = () => {
  return useReducer(reducer, initialState);
};


export const AppContext = createContext();

export const useAppState = () => {
  const [state, dispatch] = useContext(AppContext);
  return [state, dispatch];
};



export default useAppStateContext;