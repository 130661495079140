import React, { useState,useCallback } from "react";
//import { connect } from "react-redux";
import { userApi, dialogsApi } from "chatSource/utils/api";
import format from 'date-fns/format';
import intersection from "lodash/intersection";

import { Sidebar } from "chatSource/components";

import { useAppState } from "chatSource/redux/reducers/dialogs";


import { X, Search, CheckSquare, Bell, User, Trash } from 'react-feather'
 


import { dialogsActions } from 'chatSource/redux/actions';
//,items user {  }
const SidebarContainer = ({sidebarVisible,setsidebarVisible}) => {
  const [modalVisible, setmodalVisible] = useState(false); // это для модального диалога
  //const [sidebarVisible, setsidebarVisible] = useState(true); // 
  const [inputValue, setInputValue] = useState("");
  const [messageText, setMessagaText] = useState("");
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  //const [isError, setIsError] = useState(false);//!!!
  const [selectedUserId, setSelectedUserId] = useState(false);
  
   const [state, dispatch] = useAppState();
   const user = state.data;
  
  
  const [filtred, setFiltredItems] = useState(Array.from(state.messages)); // подключается из store ниже
console.log('sidebarcontainer');
  
  const onClose = useCallback(() => {
    setmodalVisible(false);
  },[]);
  
//  const onClose = () => {
//    setVisible(false);
//  };

//  const increment = () => setCounter(counter => counter + 1); 
//  const increment = useCallback(() => setCounter(counter => counter + 1), []);
    

//  const onShow = () => {
//    setVisible(true);
  //};
  
  const onShow = useCallback(() => {
    setmodalVisible(true);
  }, []);

/*  const onContactSearch = value => {
	  
	  //return;
    setIsLoading(true);
    userApi
      .findUsers(value)
      .then(({ data }) => {
        setUsers(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };*/
  const onContactSearch = useCallback(  value => {

	  //return;
    setIsLoading(true);
    userApi
      .findUsers(value)
      .then(({ data }) => {
        setUsers(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  },[]);


   const handleUserSidebarLeft =  useCallback(
   
   () => { 
		alert('1');
		setsidebarVisible(!sidebarVisible)  
	},
   []);

   
  const onAddDialog = useCallback( () => {
    dialogsApi
      .create({
        partner: selectedUserId,
        text: messageText
      })
      .then(onClose)
      .catch(() => {
        setIsLoading(false);
      });
  },[]);
//  const onAddDialog = () => {
//    dialogsApi
//      .create({
//        partner: selectedUserId,
//        text: messageText
//      })
//      .then(onClose)
//      .catch(() => {
//        setIsLoading(false);
//      });
//  };

  const handleChangeInput = useCallback(value => {
	  //alert('2');
    setInputValue(value);
  },[]);
/*  const handleChangeInput = value => {
	  //alert('2');
    setInputValue(value);
  };*/

  const onChangeTextArea = useCallback( e => {
    setMessagaText(e.target.value);
  },[]);
//  const onChangeTextArea = e => {
//    setMessagaText(e.target.value);
//  };

  const onSelectUser = useCallback( userId => {
    setSelectedUserId(userId);
  },[]);
//  const onSelectUser = userId => {
//    setSelectedUserId(userId);
//  };

  return (
    <Sidebar
      user={user}
      inputValue={inputValue}
      modalVisible={modalVisible}   // это для модального дислога
      isLoading={isLoading}
      onClose={onClose}
      onShow={onShow}
	  sidebarVisible={sidebarVisible}
      onContactSearch={onContactSearch}
      onChangeInput={handleChangeInput}
      onSelectUser={onSelectUser}
      onModalOk={onAddDialog}
      onChangeTextArea={onChangeTextArea}
      messageText={messageText}
      selectedUserId={selectedUserId}
	  handleUserSidebarLeft={handleUserSidebarLeft}  // удачнго
      users={users}
    />
  );
};



SidebarContainer.whyDidYouRender = true

//export default connect(({ user,dialogs  }) => ({ 
//user: user.data ,
//items: dialogs.items
//}))(SidebarContainer);

export default 
//connect(
//mapStateToProps
//)(
SidebarContainer
//)
;

