import React, { useEffect, useState, useRef,memo } from 'react';
//import { connect } from 'react-redux';
import { Empty, Spin } from 'antd-v3';
import find from 'lodash/find';
//import store from 'chat/redux/store'; 
import { dialogsActions, messagesActions } from 'chatSource/redux/actions'; //attachmentsActions

import { useAppState } from "chatSource/redux/reducers/dialogs";
import { useAppMessagesState } from "chatSource/redux/reducers/messages";
import './containerMessages.scss';

import { Messages as BaseMessages } from 'chatSource/components';

import { startMsgId } from 'chatSource/utils/consts';

const MessagesContainer = ({isDesktopOrLaptop,uId,currentChatType,currentChatDoc,mainDispatcher,partner,
  scrollPos,setScrollPos

}) => {

  var fetchMessages = messagesActions.fetchMessages;
  //var addMessage = dialogsActions.addMessage;
  //var setIsError = dialogsActions.setIsError;
  var removeMessageById = messagesActions.removeMessageById;
  var markReaded = messagesActions.markReaded;


    //isLoading: dialogs.isMessagesLoading,
	//isError: dialogs.isMessagesError, // удачно подцепилось из хранилища. изменяется в экшене

   const [appstate, dispatch] = useAppState(); //user, dispatch
   const [messagesstate, messagesDispatch] = useAppMessagesState(); //user, dispatch
   
   const [offset,setOffset] = useState(0); // ИСКЛЮЧИТЕЛЬНО ЛОКАЛЬНЫЙ СТАТУС. Сбрасывается в эффекте при смене ИД диалога
  
//const socket = null;
//const connected = false;

     var items =  {...messagesstate.messages}; // тут внури и сообщения и пользователи. двумя массивами
	 //alert(JSON.stringify(items.MESSAGES));
//	 if (items.MESSAGES)
//	 alert('l = '+(items.MESSAGES.length));

  const [previewImage, setPreviewImage] = useState(null);
  const [blockHeight, setBlockHeight] = useState(150);
  const [isTyping, setIsTyping] = useState(false);
  const [loading, setLoading] = useState(false);
//2022  const [scrollPos, setScrollPos] = useState(999999999); 
//  const [page, setPage] = useState(1);
  const [localMessages, setlocalMessages] = useState({});
  
  //var localMessages = {};
  //const [localMessages, setlocalMessages] = useState(  {
  //MESSAGES:items.MESSAGES,
  //USERS:items.USERS,
  //maxId:items.maxId
 // }
//);
  const pageSize = 20;	
  const messagesRef = useRef(null);
  
  
  
  function getChatIdByTypeAndDocId (type,docId) {
	  var res = appstate.messages.filter(item => item.CHAT_DOC_ID == docId && item.CHAT_TYPE == type);
	  if (res.length !=0) {
		  return res[0].CHAT_ID;
	  } else {
		  return undefined;
	  }
  }
  

  var attachments = messagesstate.attachments;   

    var isLoading= messagesstate.isMessagesLoading;
	var isError= messagesstate.isMessagesError; // удачно подцепилось из хранилища. изменяется в экшене
	
	console.log('***************************************************************************');
	console.log(messagesstate)

    var intDialogId = getChatIdByTypeAndDocId(currentChatType,currentChatDoc);



  useEffect(() => {
//2021    socket.on('DIALOGS:TYPING', toggleIsTyping);
  }, []);

  useEffect(() => {
    if (attachments.length) {
      setBlockHeight(245);
    } else {
      setBlockHeight(150);
    }
  }, [attachments]);


/*  useEffect(() => {
	  //alert('page '+page);
	  // items
	  //alert(messagesstate.messages.length);
	  //console.log(items);
	  var filter = (el,index,StartIndex,EndIndex) => {
				  if (index >= StartIndex && index <= EndIndex) {
					  return el;
				  }
		  
		  
	  };
	  
	  if (messagesstate.messages.MESSAGES) {
		      setLoading(true);
		      var EndIndex = messagesstate.messages.MESSAGES.length
		      var StartIndex = EndIndex - (page*pageSize);
			  //alert(JSON.stringify(messagesstate.messages));
			  var z =  messagesstate.messages.MESSAGES.filter(
			  function (element,index) {
				  return filter(element,index,StartIndex,EndIndex)
				  
			  }
			  
			  )
	  			  //alert(JSON.stringify(z));
				  //alert('filtered lengtj '+JSON.stringify(z.length));
				 // if (localMessages.MESSAGES)
				 // alert('before '+localMessages.MESSAGES.length);
				  setlocalMessages({...items, MESSAGES: z });
				 // if (localMessages.MESSAGES)
				 // alert('after '+localMessages.MESSAGES.length);
				  setLoading(false);
				  //localMessages = {...items};
				  //setLoading(false)
	  	  }
	  
  }, [page,messagesstate.messages]);
*/
	//alert('1!! '+appstate.currentChatDoc);
	//alert('2!! '+currentChatDoc);
	//alert(JSON.stringify(appstate));
  useEffect(() => {
		//alert('e '+currentChatDoc+' '+appstate.messages.length);
		if (currentChatDoc)  {
		// сбросим все аттачменты
		setOffset(0);
//		alert('11111111111');
		console.log(isTyping);
		console.log(loading);
		messagesDispatch(messagesActions.clearAttachments(''));

		// вот это вот фетчит все сообщения выбранного диалога
		// тут передаем то ИД, которое в локальном хранищище
 		var msgs = localStorage.getItem('msgs'+'_'+appstate.data.id);
		msgs = JSON.parse(msgs);
//		var offset = 0;
		// тут модет быть ситуация, когда не загрузились еще диалоги слева. а уже грузятся сообщения
		var cdId = getChatIdByTypeAndDocId(currentChatType,currentChatDoc);
//		if (msgs) {
//		  if (msgs[cdId]) {	
			// тут берем последнюю ЗАГРУЖЕННУЮ
//			offset = msgs[cdId].offset;
//		  }
			
//		} 
	  if (cdId) {
      fetchMessages(cdId,startMsgId,uId,messagesDispatch);
	  //setOffset(20);
	  
		if (messagesRef.current) {
//		        console.log('scrolling to '+' '+scrollPos+' full height'+messagesRef.current.scrollHeight+' '+localMessages.MESSAGES.length);
				//messagesRef.current.scrollTo(0, 2100);
		}
	  
	  
	  
	  // сбросить надо, чтоб корректно скролилось и по страницам отображалось
	  //2022setScrollPos(999999999); 
	  }
	  //setPage(1);
    }

    //2021socket.on('SERVER:NEW_MESSAGE', onNewMessage);

    //2021return () => socket.removeListener('SERVER:NEW_MESSAGE', onNewMessage);
	// кривое-тупое условие. временное TODO 2022
	// но теперь все четко при новом сообщении - вообще не запрашивает с сервера ничего , а как и задумано нработает с локальным стейтом
	// но при совсем новом сообщении и новом диалог все ж срабатывает эффект который грузит сообщения с сервера. тк длина диалогов изменилась
  }, [currentChatDoc,appstate.messages.length]); // привязка именно такая. чтоб открывалось по ссылке самого диалога. хотя может и криво TODO ,appstate , ,appstate.messages


//  useEffect (() => {
//		if (messagesRef.current) {
//		        console.log('scrolling to '+' '+scrollPos+' full height'+messagesRef.current.scrollHeight+' '+localMessages.MESSAGES.length);
//				messagesRef.current.scrollTo(0, scrollPos);
//		}


//  },[scrollPos]);	  

  useEffect(() => {
	  const onScroll = () => {
		  if (messagesRef.current) 
		  console.log('scroll '+messagesRef.current.scrollHeight+':'+messagesRef.current.scrollTop);
		  //console.log('scrollTop '+messagesRef.current.scrollTop);
		  
		  
		  if (messagesRef && messagesRef.current && messagesRef.current.scrollTop ===0) {
			  var cdId = getChatIdByTypeAndDocId(currentChatType,currentChatDoc);
				var msgs = localStorage.getItem('msgs'+'_'+appstate.data.id);
				msgs = JSON.parse(msgs);

//		var offset = 0;
//		if (msgs) {
//		  if (msgs[cdId]) {	
			// тут берем последнюю ЗАГРУЖЕННУЮ
//			offset = msgs[cdId].offset;
//		  }
			
//		} 
			// вот тут вот попытка запомнить высоту скрола для рассчета позиции	
			  var scrollHeight = messagesRef.current.scrollHeight;
			  var off = Math.min.apply(Math, messagesstate.messages.MESSAGES.map(function(o) { return o.MSG_ID; }));
			  fetchMessages(cdId,off,uId,messagesDispatch);
			  setOffset(offset+20);
			  
		if (messagesRef.current) {
//		        console.log('scrolling to '+' '+scrollPos+' full height'+messagesRef.current.scrollHeight+' '+localMessages.MESSAGES.length);
				messagesRef.current.scrollTo(0, 2100); /// вот это не отработает, тк скорее всего пересоздается на этом этапе список сообщений
		}
			  
			  setScrollPos(scrollHeight); 
		  }
		  
		  
		  
		  
		  
		  
	  }
	  
	 if (messagesRef && messagesRef.current) {
		messagesRef.current.addEventListener('scroll',onScroll);

	 }		 
	 return () => 
	 {
		 // вот тут вот нужна именно эта проверка
		 	 if (messagesRef && messagesRef.current) {
		 

	 messagesRef.current.removeEventListener('scroll',onScroll);
			 }
	 }
	  
	   
  }); // второй параметр иначе не видит текущее значение страницы в скроле
  //,[messagesRef.current,offset]
  // вроде как работает без зависимостей 2022




  useEffect(() => {
	  console.log('after');
	  //removeMessageById(0);
	  console.log(messagesstate);
	  if (messagesstate.messages && messagesstate.messages.MESSAGES) {
		  // получаем список непрочитанных
		 var f =  messagesstate.messages.MESSAGES.filter(item => item.USER.ID != uId && item.MSG_SEEN === 0 )

		// метим все сообщения как прочитанные
		if (f.length !==0) {
			    console.log('first');
				//console.log(store.getState());
				var a = [];	
				for (var prop in f) {
				  //console.log("f." + prop + " = " + f[prop].MSG_ID);
				  a.push(f[prop].MSG_ID)
				}		 
				// отправляем на сервер что все сообщения прочитанны. и после отправки метим их все как прочитанные
				console.log('markreader');
				var cdId = getChatIdByTypeAndDocId(currentChatType,currentChatDoc);
				markReaded(a,messagesDispatch,cdId,uId);
			    console.log('second');
				//console.log(store.getState());
				// ставим в локальном сторе диалогов у этого диалога колво непрочитанных в НОЛЬ
			 dialogsActions.updateReadedStatusLocal(cdId,mainDispatcher);
			    console.log('third');
				//console.log(store.getState());

		}
//		тут надо обновить в хранилище диалог и убрать количество непрочитанных
			    console.log('forth');
				//console.log(store.getState());

		console.log(localMessages);
		//console.log(appstate.data);
		//alert(localMessages.MESSAGES.length);
		//alert(messagesRef.current.offsetTop)
		// тут наверно такой хак. если размер сообщений равен начальному, то скролировать. иначе нет
		//if (messagesRef.current && localMessages.MESSAGES.length <= pageSize ) 
		{  //2021
		}
	  }
	  
		if (messagesRef.current) {
		        console.log('scrolling to '+' '+scrollPos+' full height'+messagesRef.current.scrollHeight);
				if (scrollPos != 999999999) {
						messagesRef.current.scrollTo(0, messagesRef.current.scrollHeight-scrollPos);
				} else {
					messagesRef.current.scrollTo(0,scrollPos);
					
				}
		}
	  
    
  }, [ messagesstate ]); //localMessages, isTyping, loading



  if (isError) {
    return <Empty description="Ошибка загрузки сообщений" />;
  }

  if (!currentChatDoc) {
	  if (!appstate.isCreating) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Откройте диалог11" />
	  else  return <Empty description="Напишите сообщение!" />
  }
  
  if (currentChatDoc && messagesstate.isMessagesLoading) {
	  return <Spin size="large"/>
	  
  }


  let typingTimeoutId = null;
  
  



  const onNewMessage = data => {
	  // вот это вот убираем НАФИГ все обрабатывается в socket
    //addMessage(data);
  };

  const toggleIsTyping = () => {
    setIsTyping(true);
    clearInterval(typingTimeoutId);
    typingTimeoutId = setTimeout(() => {
      setIsTyping(false);
    }, 3000);
  };
  if (Object.keys(items).length !== 0) { // костыль?????
  //alert('final length'+items.MESSAGES.length+' '+page);
  return <Messages globalUser={uId} messagesRef = {messagesRef}  isLoading = {isLoading} removeMessageById = {removeMessageById}
  setPreviewImage={setPreviewImage} previewImage = {previewImage} blockHeight={blockHeight} isTyping={isTyping} 
  partner={partner}
  
	  mainDispatcher={mainDispatcher}
	  messagesDispatch={messagesDispatch}
	  isDesktopOrLaptop={isDesktopOrLaptop}
	  currentChatType={currentChatType}
	  currentChatDoc={currentChatDoc}
	  messagesstate={messagesstate}
	  
	  
  /> 
  } else 
  {
	  // тут мы если нет items 
	  if ((appstate.messages.length != 0) && (!intDialogId)) {
		 // если есть диалоги, но ИД диалога мы не нашли в списке
		//		 в теории это новый диалог - создания
		     return <div  className="mychat__containerMessages">  <Empty description="Напишите сообщение!" />   </div>
	  } else 
 	     return null
  
  }
}

const Messages = memo( ({
	globalUser,
	messagesRef,
	items,
	isLoading,
	removeMessageById,
	setPreviewImage,
	previewImage,
	blockHeight,
	isTyping,
//	currentDialog,
	partner,
	messagesDispatch,
	mainDispatcher,
	isDesktopOrLaptop,
	currentChatType,currentChatDoc,messagesstate
	}
	) => {


  return (
    <BaseMessages
      globalUser={globalUser}
      blockRef={messagesRef}
      items={items}
      isLoading={isLoading && !globalUser}
      onRemoveMessage={removeMessageById}
      setPreviewImage={setPreviewImage}
      previewImage={previewImage}
      blockHeight={blockHeight}
      isTyping={isTyping}
      partner={partner}
	  mainDispatcher={mainDispatcher}
	  messagesDispatch={messagesDispatch}
	  isDesktopOrLaptop={isDesktopOrLaptop}
	  messagesstate={messagesstate}
    />
  );
}
);

Messages.whyDidYouRender = true
Messages.displayName = 'Messages'
MessagesContainer.whyDidYouRender = true
MessagesContainer.displayName = 'MessagesContainer'

export default //connect(
//messages, 
// это ссылки на сторы. которые меняются редьюсерами
//dialogs, user, 
//  ({  attachments }) => ({ //первый параметр это точно из store, значение, которое задавалось в редьюсере диалогов
    //items: dialogs.messages, // тут внури и сообщения и пользователи. двумя массивами
    //isLoading: dialogs.isMessagesLoading,
	//isError: dialogs.isMessagesError, // удачно подцепилось из хранилища. изменяется в экшене
//    attachments: attachments.items,
//    user: user.data,
//  }),
//  dialogsActions, //,
//)(
MessagesContainer
//)
;
